<template>
  <header class="topnav">
    <div class="topnav__primary">
      <div class="container d-flex align-items-center justify-content-between">
        <div class="topnav__item logo__area">
          <a href="">
            <img
              :src="orgBranding.iconImage | base64ToImg"
              class="topnav__logo img-fluid"
              alt="logo"
              srcset=""
              v-if="orgBranding.iconImage && !isLoading"
            />
            <img
              src="../../../assets/img/default.jpeg"
              class="topnav__logo img-fluid"
              alt="logo"
              srcset=""
              v-if="!orgBranding.iconImage"
              style="width: 36px"
            />
          </a>
        </div>

        <div style="display:flex;flex-direction:column">
          <div class="topnav__item">
            <div style="display:flex;justtify-content:space-between;width:100%">
              <div style="width: 70%">
                <input
                  type="text"
                  class="topnav__input input"
                  v-model="searchText"
                  :disabled="searchArea === ''"
                  @input="querySearch"
                  placeholder="Search for anything you want"
                />
              </div>
              <div style="width: 30%;padding-right: 65px">
                <select v-model="searchArea" style="height: 35px;width: 150px;color: white">
                  <option style="color:black !important" disabled value="">Select search</option>
                  <option style="color:black !important" value="customer">Customers</option>
                  <!-- <option value="user">Users</option> -->
                  <option style="color:black !important" value="group">Groups</option>
                  <option style="color:black !important" value="loan">Loans</option>
                  <option style="color:black !important" value="deposit">Deposits</option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="searchValue.length" class="search__box">
            <div style="width: 20%;">
              <span
               class="
                page__body__header__button
                text--capital
                d-inline-flex mr-2
                search__item
               "
               style="background:#ffdecc; font-weight:700; color:#000"
              >
                {{ searchArea+'s' }}
              </span>
            </div>
            <div style="border-left: 1px solid #000;"></div>
              <div
                style="width: 80%;"
              >
              <div
                v-for="(value, i) in searchValue"
                :key="i"
                class="search__result__item"
              >
                <span
                  v-if="i == 0"
                  class="
                    text--capital
                    page__body__header__button
                  "
                  style="justify-content: flex-start; height: 24px;
                    cursor: pointer;
                    background: #ffdecc;
                    font-weight: 700;
                    color: #000;
                  "
                  @click="openCustomer(value.linkKey, searchArea)"
                >
                  {{ value.name }}
                </span>
                <span
                  v-else
                  class="
                    text--capital
                    page__body__header__button
                    hover--text
                  "
                  style="justify-content: flex-start; height: 24px; cursor:pointer;"
                  @click="openCustomer(value.linkKey, searchArea)"
                >
                  {{ value.name }}
                </span>
              </div>
            </div>
          </div>

          <div
            class="search__box"
            style="justify-content: center;"
            v-else-if="!searchValue.length && loadSearch"
          >
            <div
              class="spinner-border"
              style="width: 2rem; height: 2rem"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>

        <div class="topnav__item dropdown dropdown__hover">
          <button
            class="
              button
              dropdown__toggle
              topnav__dropdown topnav__dropdown--dark
              text-light
            "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-offset="0,5"
          >
            create
            <span class="dropdown__icon">
              <img src="../../../assets/img/caret-down.svg" alt="" srcset="" />
            </span>
          </button>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <div class="dropdown__menu__container--content">
                <a
                  class="dropdown-item"
                  data-toggle="modal"
                  data-target="#createCustomer"
                  @click="preOpenCustomerForm()"
                  v-if="userCanCreateCustomer"
                  >Customer</a
                >
                <div v-if="userCanCreateCustomer">
                  <a
                    v-for="item in customerClientTypes"
                    v-bind:key="item.customerTypeKey"
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#createCustomer"
                    @click="preOpenCustomerForm(item.customerTypeKey)"
                    >{{ item.clientTypeName }}</a
                  >
                </div>
                <a
                  class="dropdown-item new--item"
                  data-toggle="modal"
                  data-target="#createGroupModal"
                  @click="openGroupModal"
                  v-if="userCanCreateGroup"
                  >Groups</a
                >
                <a @click="goToLoan" class="dropdown-item new--item" v-if="userCanCreateLoan">Loan Account</a>
                <a 
                  class="dropdown-item new--item"
                  data-toggle="modal"
                  data-target="#newDepositModal"
                  @click="openDepositModal"
                  v-if="userCanCreateDeposit"
                  >Deposit Account</a
                >
                <a
                  @click="openCreateModal()"
                  v-if="userCanCreateUser"
                  class="dropdown-item new--item"
                >Users</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="topnav__item dropdown dropdown__hover">
          <div class="d-flex align-items-center">
            <img
              src="../../../assets/img/notification.svg"
              class="mr-3"
              alt="zedvance"
              srcset=""
            />
            <div class="profile">
              <div
                class="profile__avatar"
                :style="{ backgroundImage: `url(${userImg})` }"
              >
                <!-- <img src="assets/img/user.png" alt="user" srcset=""> -->
              </div>
              <div class="profile__details">
                <span class="profile__text"> welcome </span>
                <div class="dropdown">
                  <span
                    class="profile__who dropdown__toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ username }}
                    <span class="dropdown__icon">
                      <img
                        src="../../../assets/img/caret-down.svg"
                        alt=""
                        srcset=""
                      />
                    </span>
                  </span>
                  <div
                    class="dropdown__menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div class="dropdown__menu__container">
                      <a class="dropdown-item" href="#" @click="openChangePasswordModal"
                        >Change Password</a
                      >
                      <a class="dropdown-item" href="#" @click="logout"
                        >Logout</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="topnav__secondary">
      <div class="container d-flex align-items-center justify-content-between">
        <!--        <a class="topnav__link">overview</a>-->
        <router-link to="/dashboard" class="topnav__link"
          >DashBoard</router-link
        >
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            clients
            <span class="dropdown__icon">
              <img src="assets/img/caret-down-black.svg" alt="" srcset="" />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <!-- <a class="dropdown-item" href="client-active.html">Active</a>
              <a class="dropdown-item" href="client-inactive.html">Inactive</a>
              <a class="dropdown-item" href="client-pending.html"
                >Pending approvals</a
              >
              <a class="dropdown-item" href="client-exited.html">Exited</a>
              <a class="dropdown-item" href="client-blacklisted.html"
                >Blacklisted</a
              > -->
              <template v-if="isInGroupMenu('clients')">
                <template
                  v-for="(row, index) in isInGroupMenu('clients').viewMenus"
                >
                  <router-link
                    :to="`/viewreport/${row.viewKey}`"
                    class="dropdown-item"
                    :key="index"
                    v-if="userCanViewViews"
                    >{{ row.viewName }}</router-link
                  >
                </template>
              </template>

              <router-link tag="a" class="dropdown-item" to="/all-customers" v-if="userCanViewCustomer"
                >All Customers</router-link
              >
            </div>
          </div>
        </div>
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            groups
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('Groups')">
                <template
                  v-for="(row, index) in isInGroupMenu('Groups').viewMenus"
                >
                  <router-link
                    :to="`/viewreport/${row.viewKey}`"
                    class="dropdown-item"
                    :key="index"
                    v-if="userCanViewViews"
                    >{{ row.viewName }}</router-link
                  >
                </template>
              </template>
              <!-- <a class="dropdown-item" href="groups-loan.html"
                >Active Loan Account</a
              >
              <a class="dropdown-item" href="groups-deposit.html"
                >Active Deposit Account
              </a>
              <a class="dropdown-item" href="groups-active.html"
                >No Active Account</a
              >
              <a class="dropdown-item" href="groups-members.html">No Members</a> -->
              <router-link tag="a" class="dropdown-item" to="/all-groups" v-if="userCanViewGroups"
                >All Groups</router-link
              >
            </div>
          </div>
        </div>
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            deposit
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('Deposits')">
                <template
                  v-for="(row, index) in isInGroupMenu('Deposits').viewMenus"
                >
                  <router-link
                    :to="`/viewreport/${row.viewKey}`"
                    class="dropdown-item"
                    :key="index"
                    v-if="userCanViewViews"
                    >{{ row.viewName }}</router-link
                  >
                </template>
              </template>

              <router-link to="/deposit-accounts" class="dropdown-item" v-if="userCanViewDeposits"
                >All Deposit Account</router-link
              >
            </div>
          </div>
        </div>
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            loans
            <span class="dropdown__icon">
              <img src="assets/img/caret-down-black.svg" alt="" srcset="" />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('Loans')">
                <template
                  v-for="(row, index) in isInGroupMenu('Loans').viewMenus"
                >
                  <router-link
                    :to="`/viewreport/${row.viewKey}`"
                    class="dropdown-item"
                    :key="index"
                    v-if="userCanViewViews"
                    >{{ row.viewName }}</router-link
                  >
                </template>
              </template>
              <!-- <router-link to="/loans" class="dropdown-item"
                >Repayment Collection</router-link
              > -->
              <!-- <router-link to="/loans" class="dropdown-item"
                >Loan Transaction</router-link
              > -->
              <!-- <router-link to="/loan/products" class="dropdown-item"
                >All Loan Products</router-link
              > -->
              <router-link to="/loan/accounts" class="dropdown-item" v-if="userCanViewLoans"
                >All Loan Accounts</router-link
              >
              <!-- <router-link to="/loan/pendingorpartialaccounts" class="dropdown-item" v-if="userCanViewLoans"
                >Pending/Partial Loan Accounts</router-link
              > -->
            </div>
          </div>
        </div>
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            loan transactions
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('Loan Transactions')">
                <template
                  v-for="(row, index) in isInGroupMenu('Loan Transactions').viewMenus"
                >
                  <router-link
                    :to="`/viewreport/${row.viewKey}`"
                    class="dropdown-item"
                    :key="index"
                    v-if="userCanViewViews"
                    >{{ row.viewName }}</router-link
                  >
                </template>
              </template>
              <router-link class="dropdown-item" v-if="userCanViewLoanTransactions" to="/all-loan-transactions">All Loan Transactions</router-link>
            </div>
          </div>
        </div>
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            deposit transactions
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('Deposit Transactions')">
                <template v-for="(row,index) in isInGroupMenu('Deposit Transactions').viewMenus" >

                <router-link :to="`/viewreport/${row.viewKey}`" class="dropdown-item" :key="index" v-if="userCanViewViews"
                >{{ row.viewName }}</router-link>
                </template>
              </template>
              <!-- <a class="dropdown-item" href="#">Deposit Collection</a> -->
              <router-link class="dropdown-item" to="/all-deposit-transactions" v-if="userCanViewDepositTransactions">All Deposit Transactions</router-link>
            </div>
          </div>
        </div>
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            activities
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('Activities')">
                <template v-for="(row,index) in isInGroupMenu('Activities').viewMenus" >

                <router-link :to="`/viewreport/${row.viewKey}`" class="dropdown-item" :key="index" v-if="userCanViewViews"
                >{{ row.viewName }}</router-link>
                </template>
              </template>
              <router-link class="dropdown-item" v-if="userCanViewActivities" to="/all-activities">All Activities</router-link>
            </div>
          </div>
        </div>
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            branches
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('Branches')">
                <template
                  v-for="(row, index) in isInGroupMenu('Branches').viewMenus"
                >
                  <router-link
                    :to="`/viewreport/${row.viewKey}`"
                    class="dropdown-item"
                    :key="index"
                    v-if="userCanViewViews"
                    >{{ row.viewName }}</router-link
                  >
                </template>
              </template>
              <router-link class="dropdown-item" to="/all-branches" v-if="userCanViewBranches"
                >All Branches</router-link
              >
            </div>
          </div>
        </div>
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            centers
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('centres')">
                <template
                  v-for="(row, index) in isInGroupMenu('centres').viewMenus"
                >
                  <router-link
                    :to="`/viewreport/${row.viewKey}`"
                    class="dropdown-item"
                    :key="index"
                    v-if="userCanViewViews"
                    >{{ row.viewName }}</router-link
                  >
                </template>
              </template>
              <router-link class="dropdown-item" to="/all-centres" v-if="userCanViewCenters"
                >All Centers</router-link
              >
            </div>
          </div>
        </div>
        <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            users
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('Users')">
                <template
                  v-for="(row, index) in isInGroupMenu('Users').viewMenus"
                >
                  <router-link
                    :to="`/viewreport/${row.viewKey}`"
                    class="dropdown-item"
                    :key="index"
                    v-if="userCanViewViews"
                    >{{ row.viewName }}</router-link
                  >
                </template>
              </template>
              <router-link class="dropdown-item" to="/all-users" v-if="userCanViewUsers"
                >All Users</router-link
              >
            </div>
          </div>
        </div>
        <!-- <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            installments
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <a class="dropdown-item" href="#">All Installments</a>
            </div>
          </div>
        </div> -->

        <!-- <div class="dropdown dropdown__hover">
          <span
            class="dropdown__toggle topnav__secondary__dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Communications
            <span class="dropdown__icon">
              <img
                src="../../../assets/img/caret-down-black.svg"
                alt=""
                srcset=""
              />
            </span>
          </span>
          <div class="dropdown__menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown__menu__container">
              <template v-if="isInGroupMenu('Communications')">
                <template v-for="(row,index) in isInGroupMenu('Communications').viewMenus" >

                <router-link :to="`/viewreport/${row.viewKey}`" class="dropdown-item" :key="index"
                >{{ row.viewName }}</router-link>
                </template>
              </template>
              <a class="dropdown-item" href="#">Active</a>
              <a class="dropdown-item" href="#">Inactive</a>
              <a class="dropdown-item" href="#">Pending approvals</a>
              <a class="dropdown-item" href="#">Exited</a>
              <a class="dropdown-item" href="#">Blacklisted</a>
            </div>
          </div>
        </div> -->

        <div class="">
          <button
            class="button p-0 topnav__secondary__more"
            id="more__btn"
            type="button"
          >
            more
            <span class="d-inline-flex topnav__secondary__more__icon">
              <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
            </span>
          </button>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="viewGroups"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#viewGroups"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Quick Lookup On Group
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <ul id="accordion">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#vgfilter"
                    aria-expanded="false"
                    aria-controls="vgfilter"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      filter
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="vgfilter" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="form__item">
                        <div class="p-5 border-grey border-radius">
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">Where</label>
                                <select class="select select--lg">
                                  <option value="" disabled>
                                    Select Where
                                  </option>
                                  <option value="" selected>
                                    Loan Balance
                                  </option>
                                  <option value="">Account Balance</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">More than</label>
                                <select class="select select--lg">
                                  <option value="" disabled selected>
                                    Select Value
                                  </option>
                                  <option value="">Option 1</option>
                                  <option value="">Option 2</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            class="row align-items-end justify-content-between"
                          >
                            <div class="col-6">
                              <div class="form__item mb-0">
                                <label class="form__label">Enter Amount</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  placeholder="Enter Amount"
                                />
                              </div>
                            </div>
                            <div class="px-4">
                              <button
                                type="button"
                                class="button button--cancel text--capital"
                              >
                                <span class="d-inline-flex">delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form__item">
                        <div class="p-5 border-grey border-radius">
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">And </label>
                                <select class="select select--lg">
                                  <option value="" disabled>
                                    Select Where
                                  </option>
                                  <option value="" selected>
                                    Loan Balance
                                  </option>
                                  <option value="">Account Balance</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">Equals</label>
                                <select class="select select--lg">
                                  <option value="" disabled selected>
                                    Select Value
                                  </option>
                                  <option value="">Option 1</option>
                                  <option value="">Option 2</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            class="row align-items-end justify-content-between"
                          >
                            <div class="col-6">
                              <div class="form__item mb-0">
                                <label class="form__label">Enter Amount</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  placeholder="Enter Amount"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-right row mt-4 mb5">
                          <div class="col ml-auto">
                            <div>
                              <select class="select select--lg w-auto">
                                <option value="" disabled>Select Filter</option>
                                <option value="" selected>Match All</option>
                                <option value="">Match None</option>
                              </select>
                              <button
                                type="button"
                                class="
                                  ml-5
                                  button button--grey
                                  text--black text--capital
                                "
                              >
                                <span class="d-inline-flex">Add Filter</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#vgfields"
                    aria-expanded="false"
                    aria-controls="vgfields"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      fields
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="vgfields" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="form__item">
                        <label class="form__label">Available Columns</label>
                        <select class="select select--lg">
                          <option value="" disabled selected>
                            Select available columns
                          </option>
                          <option value="">option 1</option>
                          <option value="">option 2</option>
                          <option value="">option 3</option>
                        </select>
                      </div>
                      <div class="form__item">
                        <label class="form__label">Selected Columns</label>
                        <div class="form__item_dottedbg px-5 py-3">
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              full name
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              ID
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              customer state
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              account officer
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              loan deposit
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              deposit balance
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              last modified
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-end">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">sort by</label>
                            <input
                              type="text"
                              class="input form__input form__input--lg"
                              placeholder="Enter Title"
                            />
                          </div>
                          <div class="form__item">
                            <label
                              for="includeTotal2"
                              class="
                                d-block
                                has-checkbox
                                page__body__header__label
                                text--grey text--capital
                                mb-3
                                mr-3
                              "
                              >Include Totals
                              <input
                                type="checkbox"
                                id="includeTotal2"
                                class="form-"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <select class="select select--lg">
                              <option value="" disabled selected>
                                ascending order
                              </option>
                              <option value="">option 1</option>
                              <option value="">option 2</option>
                              <option value="">option 3</option>
                            </select>
                          </div>
                          <div class="form__item">
                            <label
                              for="includeTimestamp2"
                              class="
                                d-block
                                has-checkbox
                                page__body__header__label
                                text--grey text--capital
                                mb-3
                                mr-3
                              "
                              >Include Timestamp
                              <input
                                type="checkbox"
                                id="includeTimestamp2"
                                class="form-"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="button"
                    data-dismiss="#viewGroups"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                  >
                    apply changes
                  </button>
                  <a
                    role="button"
                    data-dismiss="#viewGroups"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <div
      class="modal modal__right"
      id="createGroupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                id="createGroupCloseButton"
                data-dismiss="modal"
                @click="clearGroupForm"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Creating a Group</h5>
          </div>
          <div class="modal__body">
            <form @submit="saveGroupForm">
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Group Name</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Group Name"
                      v-model="groupForm.groupName"
                      v-bind:class="{
                        'input-required': groupErrorForm.groupName,
                      }"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Group Customer Type</label>
                    <select
                      v-model="groupForm.clientRoleKey"
                      class="select select--lg"
                      v-bind:class="{
                        'input-required': groupErrorForm.clientRoleKey,
                      }"
                      @change="
                        getCustomFormFields('Groups', $event.target.value)
                      "
                    >
                      <option
                        v-for="item in sortGroupType"
                        v-bind:key="item.customerTypeKey"
                        :value="item.customerTypeKey"
                      >
                        {{ item.clientTypeName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div
                    v-if="groupForm.uniqueGroupId === null"
                    class="form__item"
                  >
                    <label class="form__label">Generating ID...</label>
                  </div>
                  <div class="form__item">
                    <label class="form__label">Group ID</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Group ID"
                      v-model="groupForm.uniqueGroupId"
                      :disabled="groupForm.uniqueGroupId === ''"
                      v-bind:class="{
                        'input-required': groupErrorForm.uniqueGroupId,
                      }"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <!-- <label class="form__label">Group Email</label> -->
                    <div style="padding:0px !important" class="col-12">
                      <label style="padding:0px !important" class="form__label col-4">Email</label>
                      <label
                        v-if="requiredEmail"
                        style="padding:0px !important"
                        class="form__label col-8 text-danger"
                      >Email is required</label>
                    </div>
                    <input
                      type="email"
                      class="input form__input form__input--lg"
                      placeholder="Enter Group email"
                      v-model="groupForm.groupEmailAddress"
                      @input="validateEmail(groupForm.groupEmailAddress)"
                      v-bind:class="{
                        'input-required': groupErrorForm.groupEmailAddress,
                      }"
                    />
                    <p class="text--danger" v-if="groupErrorForm.groupEmailAddress">Please provide a valid email</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Group phone number</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Group phone number"
                      v-model="groupForm.groupPhoneNumber"
                      v-bind:class="{
                        'input-required': groupErrorForm.groupPhoneNumber,
                      }"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Group Mobile number</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Group mobile number"
                      v-model="groupForm.groupMobileNumber"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Product Type</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Group Type"
                      readonly
                      value="Group"
                    />
                  </div>
                </div>
              </div>

              <ul id="accordion" class="mb5">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#cggroupMembers"
                    aria-expanded="false"
                    aria-controls="cggroupMembers"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Group Members
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="cggroupMembers"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div
                        class="row"
                        v-for="item in groupForm.memberKeyArray"
                        v-bind:key="item"
                      >
                        <div class="form__item col-8">
                          <input
                            type="text"
                            :value="groupForm.memberNames[item]"
                            class="input form__input form__input--lg"
                            disabled
                          />
                        </div>
                        <div class="form__item col-3">
                          <button
                            v-on:click="deleteMemberFromGroup(item)"
                            type="button"
                            class="
                              button
                              page__body__header__button
                              button--grey
                              text--capital
                            "
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                            <img
                              src="../../../assets/img/delete.svg"
                              alt=""
                              srcset=""
                            />
                          </button>
                        </div>
                      </div>
                      <div class="form__item">
                        <label class="form__label">Select Group Member</label>
                        <select
                          class="select select--lg"
                          v-model="selectedCustomerKey"
                        >
                          <option
                            v-for="item in allCustomers"
                            v-bind:key="item.customerKey"
                            :value="item.customerKey"
                          >
                            {{ item.customerNameWithID }}
                            <!-- {{ item.customerLastName }} -->
                          </option>
                        </select>
                      </div>
                      <div class="form__item" id="branch-area"></div>

                      <div class="text-right mt-3">
                        <button
                          @click="validateMultipleCustomer(selectedCustomerKey)"
                          type="button"
                          :disabled="enabled"
                          class="
                            d-flex
                            align-items-center
                            ml-auto
                            button button--grey
                            text--600 text--black text--small text--capital
                          "
                        >
                          {{enabled ? "Please wait..." : "Add customer to group" }}
                          <ion-icon
                            name="add-outline"
                            v-if="!enabled"
                            class="text--600 text--medium ml-2"
                          ></ion-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#cggroupRoles"
                    aria-expanded="false"
                    aria-controls="cggroupRoles"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Group Roles
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="cggroupRoles"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div
                        v-for="item in groupForm.memberRoles"
                        v-bind:key="
                          'g' + item.groupRoleNameKey + 'c' + item.clientKey
                        "
                      >
                        <div
                          class="row align-items-center"
                          v-if="
                            item.groupRoleNameKey != null &&
                            item.clientKey != null
                          "
                        >
                          <div class="col-5">
                            <div class="form__item">
                              <label class="form__label">Group Role</label>
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                :disabled="true"
                                :value="item.groupRoleName"
                              />
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form__item">
                              <label class="form__label">Members</label>
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                :disabled="true"
                                :value="item.customerName"
                              />
                            </div>
                          </div>
                          <div class="col-2">
                            <button
                              @click="
                                removeGroupRole(
                                  item.clientKey,
                                  item.groupRoleNameKey
                                )
                              "
                              type="button"
                              class="
                                button button--cancel
                                text--500 text--small text--capital
                              "
                            >
                              delete
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center">
                        <div class="col-5">
                          <div class="form__item">
                            <label class="form__label">Group Roles</label>
                            <select
                              v-model="groupRoleAddModel.groupRoleNameKey"
                              class="select select--lg"
                              v-bind:class="{
                                'input-required': groupErrorForm.groupRole,
                              }"
                            >
                              <option
                                v-for="item in allGroupRoleNames"
                                v-bind:key="item.groupRoleNameKey"
                                :value="item.groupRoleNameKey"
                              >
                                {{ item.groupRoleName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-5">
                          <div class="form__item">
                            <label class="form__label">Members</label>
                            <select
                              v-model="groupRoleAddModel.clientKey"
                              class="select select--lg"
                              v-bind:class="{
                                'input-required': groupErrorForm.groupRole,
                              }"
                            >
                              <option
                                v-for="item in groupForm.memberKeyArray"
                                v-bind:key="item"
                                :value="item"
                              >
                                {{ groupForm.memberNames[item] }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="text-right mt-3">
                        <button
                          @click="addGroupRole()"
                          type="button"
                          class="
                            d-flex
                            align-items-center
                            ml-auto
                            button button--grey
                            text--600 text--black text--small text--capital
                          "
                          :disabled="
                            groupRoleAddModel.groupRoleNameKey == null &&
                            groupRoleAddModel.clientKey == null
                          "
                        >
                          Add Group Role
                          <ion-icon
                            name="add-outline"
                            class="text--600 text--medium ml-2"
                          ></ion-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#cgassociated"
                    aria-expanded="false"
                    aria-controls="cgassociated"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Associated
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="cgassociated"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Branch</label>
                            <select
                              class="select--lg w-100"
                              v-model="groupForm.assignedBranchKey"
                              v-bind:class="{
                                'input-required': groupErrorForm.assignedBranchKey,
                              }"
                              @change="branchSelectChange($event)"
                            >
                              <option disabled>Select branch</option>
                              <option
                                v-for="item in allBranches"
                                v-bind:key="item.branchKey"
                                v-bind:value="item.branchKey"
                              >
                                {{ item.branchName }} ({{ item.branchId }})
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Center</label>
                            <select
                              class="select--lg w-100"
                              v-model="groupForm.assignedCentreKey"
                              v-bind:class="{
                                'input-required': groupErrorForm.assignedCentreKey,
                              }"
                            >
                              <option disabled>Select centre</option>
                              <option
                                v-for="item in branchCenter"
                                v-bind:key="item.centerKey"
                                v-bind:value="item.centerKey"
                              >
                                {{ item.centerName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Account Officer</label>
                            <select
                              class="select--lg w-100"
                              v-model="groupForm.assignedUserKey"
                              v-bind:class="{
                                'input-required': groupErrorForm.assignedUserKey,
                              }"
                            >
                              <option disabled>Select Account Officer</option>
                              <option
                                v-for="item in accountOfficers"
                                :key="item.userKey"
                                :value="item.userKey"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="modal__accordion"
                  v-for="(set, index) in customFields"
                  :key="set.customFieldSetKey"
                  v-show="groupForm.clientRoleKey"
                >
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    :data-target="`#ccSet${index}`"
                    aria-expanded="false"
                    aria-controls="ccprofileNote"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      {{ set.customFieldSetName }}
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    :id="`ccSet${index}`"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-for="(form, i) in set.customFields"
                          :key="form.customFieldKey"
                        >
                          <div class="form__item" v-show="isDefaultGroup(form)">
                            <label class="form__label">{{
                              form.customFieldName
                            }}</label>
                            <input
                              :type="
                                form.dataType === 'Number'
                                  ? 'number'
                                  : form.dataType === 'Date'
                                  ? 'date'
                                  : form.dataType === 'Checkbox'
                                  ? 'checkbox'
                                  : 'text'
                              "
                              v-if="(
                                form.dataType !== 'Selection' &&
                                form.dataType.toLowerCase() !== 'user link' &&
                                form.dataType.toLowerCase() !== 'group link' &&
                                form.dataType.toLowerCase() !== 'customer link'
                              )"
                              @input="groupValidateNumber(
                                $event.target.value,
                                form.dataType,
                                index,
                                i
                              )"
                              :id="form.customFieldKey"
                              :ref="form.customFieldKey"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              v-bind:class="{
                                'input-required': form.customFieldNameErr,
                              }"
                              :placeholder="`Enter ${form.customFieldName}`"
                            />
                            <select
                              class="select--lg w-100"
                              v-if="(
                                form.dataType === 'Selection' &&
                                form.dataType.toLowerCase() !== 'user link' &&
                                form.dataType.toLowerCase() !== 'group link' &&
                                form.dataType.toLowerCase() !== 'customer link'
                              )"
                              v-bind:class="{
                                'input-required': form.customFieldNameErr,
                              }"
                              :id="form.customFieldKey"
                              :ref="form.customFieldKey"
                            >
                              <option disabled selected value="null">
                                Select {{ form.customFieldName }}
                              </option>
                              <option
                                v-for="opt in form.customFieldSelections"
                                :key="opt.customFieldSelectionKey"
                                :value="opt.value"
                              >
                                {{ opt.value }}
                              </option>
                            </select>

                            <!-- User Multi select -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="'cus-' + form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="name"
                              track-by="userKey"
                              placeholder="Type to search user"
                              open-direction="bottom"
                              :options="sortedUsers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="form.dataType.toLowerCase() === 'user link'"
                              v-bind:class="{
                                'input-required': form.customFieldNameErr,
                              }"
                            >
                              <template slot="tag" slot-scope="{ option, remove }">
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span class="custom__remove" @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Group multiselect -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="'cus-' + form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="groupName"
                              track-by="groupKey"
                              placeholder="Type to search group"
                              open-direction="bottom"
                              :options="sortedGroups"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="form.dataType.toLowerCase() === 'group link'"
                              v-bind:class="{
                                'input-required': form.customFieldNameErr,
                              }"
                            >
                              <template slot="tag" slot-scope="{ option, remove }">
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span class="custom__remove" @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Customer multiselect -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="'cus-' + form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="customerFirstName"
                              track-by="customerKey"
                              placeholder="Type to search customer"
                              open-direction="bottom"
                              :options="sortedCustomers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="form.dataType.toLowerCase() === 'customer link'"
                              v-bind:class="{
                                'input-required': form.customFieldNameErr,
                              }"
                            >
                              <template slot="singleLabel" slot-scope="{ option }">
                                <span>{{ option.customerFirstName }}</span>
                                <span>&nbsp;{{ option.customerLastName }}</span>
                              </template>

                              <template slot="option" slot-scope="{ option }">
                                <div class="option__desc">
                                  <span class="option__title">
                                    {{ option.customerFirstName }}&nbsp;
                                  </span>
                                  <span class="option__small">{{
                                    option.customerLastName
                                  }}</span>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#cgprofileNote"
                    aria-expanded="false"
                    aria-controls="cgprofileNote"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Profile Note
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="cgprofileNote"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <textarea
                          cols="5"
                          rows="5"
                          class="input form__input"
                          placeholder="Profile Note"
                          v-model="groupForm.groupNotes"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                  >
                    Save group
                  </button>
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      modal__confirm__button modal__confirm__button--delete
                    "
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    v-if="!isSaving"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="clearGroupForm"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="viewCustomer"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#viewCustomer"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Quick Lookup On Customers
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <ul id="accordion">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#vcfilter"
                    aria-expanded="false"
                    aria-controls="vcfilter"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      filter
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="vcfilter" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="form__item">
                        <div class="p-5 border-grey border-radius">
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">Where</label>
                                <select class="select select--lg">
                                  <option value="" disabled>
                                    Select Where
                                  </option>
                                  <option value="" selected>
                                    Loan Balance
                                  </option>
                                  <option value="">Account Balance</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">More than</label>
                                <select class="select select--lg">
                                  <option value="" disabled selected>
                                    Select Value
                                  </option>
                                  <option value="">Option 1</option>
                                  <option value="">Option 2</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            class="row align-items-end justify-content-between"
                          >
                            <div class="col-6">
                              <div class="form__item mb-0">
                                <label class="form__label">Enter Amount</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  placeholder="Enter Amount"
                                />
                              </div>
                            </div>
                            <div class="px-4">
                              <button
                                type="button"
                                class="button button--cancel text--capital"
                              >
                                <span class="d-inline-flex">delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form__item">
                        <div class="p-5 border-grey border-radius">
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">And </label>
                                <select class="select select--lg">
                                  <option value="" disabled>
                                    Select Where
                                  </option>
                                  <option value="" selected>
                                    Loan Balance
                                  </option>
                                  <option value="">Account Balance</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">Equals</label>
                                <select class="select select--lg">
                                  <option value="" disabled selected>
                                    Select Value
                                  </option>
                                  <option value="">Option 1</option>
                                  <option value="">Option 2</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            class="row align-items-end justify-content-between"
                          >
                            <div class="col-6">
                              <div class="form__item mb-0">
                                <label class="form__label">Enter Amount</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  placeholder="Enter Amount"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-right row mt-4 mb5">
                          <div class="col ml-auto">
                            <div>
                              <select class="select select--lg w-auto">
                                <option value="" disabled>Select Filter</option>
                                <option value="" selected>Match All</option>
                                <option value="">Match None</option>
                              </select>
                              <button
                                type="button"
                                class="
                                  ml-5
                                  button button--grey
                                  text--black text--capital
                                "
                              >
                                <span class="d-inline-flex">Add Filter</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#vcfields"
                    aria-expanded="false"
                    aria-controls="vcfields"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      fields
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="vcfields" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="form__item">
                        <label class="form__label">Available Columns</label>
                        <select class="select select--lg">
                          <option value="" disabled selected>
                            Select available columns
                          </option>
                          <option value="">option 1</option>
                          <option value="">option 2</option>
                          <option value="">option 3</option>
                        </select>
                      </div>
                      <div class="form__item">
                        <label class="form__label">Selected Columns</label>
                        <div class="form__item_dottedbg px-5 py-3">
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              full name
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              ID
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              customer state
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              account officer
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              loan deposit
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              deposit balance
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              last modified
                            </p>
                            <button
                              type="button"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-end">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">sort by</label>
                            <input
                              type="text"
                              class="input form__input form__input--lg"
                              placeholder="Enter Title"
                            />
                          </div>
                          <div class="form__item">
                            <label
                              for="includeTotal3"
                              class="
                                d-block
                                has-checkbox
                                page__body__header__label
                                text--grey text--capital
                                mb-3
                                mr-3
                              "
                              >Include Totals
                              <input
                                type="checkbox"
                                id="includeTotal3"
                                class="form-"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <select class="select select--lg">
                              <option value="" disabled selected>
                                ascending order
                              </option>
                              <option value="">option 1</option>
                              <option value="">option 2</option>
                              <option value="">option 3</option>
                            </select>
                          </div>
                          <div class="form__item">
                            <label
                              for="includeTimestamp3"
                              class="
                                d-block
                                has-checkbox
                                page__body__header__label
                                text--grey text--capital
                                mb-3
                                mr-3
                              "
                              >Include Timestamp
                              <input
                                type="checkbox"
                                id="includeTimestamp3"
                                class="form-"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="button"
                    data-dismiss="#viewCustomer"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                  >
                    apply changes
                  </button>
                  <a
                    role="button"
                    data-dismiss="#viewCustomer"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- #region CustomerFormRegion -->
    <div
      class="modal modal__right"
      id="createCustomer"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="modal"
                id="customerFormCloseButton"
                class="modal__close modal__close--black"
                @click="clearCustomCustomer"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Creating a Customer
            </h5>
          </div>
          <div class="modal__body">
            <form class="form">
              <ul id="accordion" class="mb5">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#ccgeneral"
                    aria-expanded="false"
                    aria-controls="ccgeneral"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      General
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="ccgeneral" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">First Name</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter First Name"
                              v-model="customerForm.customerFirstName"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.customerFirstName,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Middle Name</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Middle Name"
                              v-model="customerForm.customerMiddleName"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Last Name</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Last Name"
                              v-model="customerForm.customerLastName"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.customerLastName,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Customer Type</label>

                            <select
                              class="select--lg w-100"
                              v-model="customerForm.customerTypesKey"
                              @change="
                                getCustomFormFields(
                                  'Customers',
                                  $event.target.value
                                )
                              "
                              required
                            >
                              <option disabled value="null">
                                Select Customer type
                              </option>
                              <option
                                v-for="item in sortCustomerType"
                                v-bind:key="item.customerTypeKey"
                                v-bind:value="item.customerTypeKey"
                              >
                                {{ item.clientTypeName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div
                            v-if="customerForm.uniqueCustomerId === ''"
                            class="form__item"
                          >
                            <label class="form__label">Generating ID...</label>
                          </div>
                          <div v-else class="form__item">
                            <label class="form__label">
                              ID
                              <i
                                class="bi bi-info-circle-fill"
                                data-toggle="tooltip"
                                data-placement="top"
                                style="font-size: 1.6rem"
                                title="Select a Customer type to generate an ID, The ID can be edited after it has generated by the system"
                              ></i>
                            </label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="ID generated"
                              :disabled="customerForm.uniqueCustomerId === null"
                              v-model="customerForm.uniqueCustomerId"
                              v-bind:class="{
                                'input-required':
                                customerErrorForm.uniqueCustomerId,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div
                            v-if="isDeleting"
                            class="form__item"
                          >
                            <label class="form__label">Validating BVN...</label>
                          </div>
                          <div class="form__item" v-else style="margin-bottom: 2rem !important">
                            <div style="padding:0px !important" class="col-12">
                              <label style="padding:0px !important" class="form__label col-4">BVN</label>
                              <label
                                v-if="requiredBVN"
                                style="padding:0px !important"
                                class="form__label col-8 text-danger"
                              >BVN is required</label>
                            </div>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Bvn"
                              @input="limitBVNEntry"
                              @keyup="validateBVNRequest"
                              v-model="customerForm.bvn"
                              v-bind:class="{
                                'input-required': customerErrorForm.bvn,
                              }"
                            />
                          </div>
                          <transition name="slide" type="animation">
                            <div class="container" v-if="bvnInfo">
                              <div class="card">
                                <div class="title">
                                  <h1>
                                    {{`${bvnInfo.firstname} ${bvnInfo.middlename} ${bvnInfo.lastname}`}}
                                  </h1>
                                  <h2>{{ bvnInfo.bvn }}</h2>
                                </div>
                                <div class="content">
                                  <div class="social">
                                    <p>DOB: {{ bvnInfo.dateOfBirth | moment }}</p>
                                  </div>
                                  <div class="social">
                                    <p>Email: {{ bvnInfo.email ?? "" }}</p>
                                  </div>
                                  <div class="social">
                                    <p>Phone: {{ bvnInfo.mobileNumber }}</p>
                                  </div>
                                  <div class="social">
                                    <p>Gender: {{ bvnInfo.gender }}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#ccdetails"
                    aria-expanded="false"
                    aria-controls="ccdetails"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Details
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="ccdetails" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Date of Birth</label>
                            <input
                              type="date"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Date of Birth"
                              :max="currDate"
                              v-model="customerForm.customerBirthDate"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.customerBirthDate,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Gender</label>
                            <select
                              class="select select--lg"
                              v-model="customerForm.customerGender"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.customerGender,
                              }"
                            >
                              <option value="null" disabled>
                                Select Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Mobile Phone</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Mobile Phone"
                              v-model="customerForm.customerMobilePhone1"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.customerMobilePhone1,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Home Phone</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder=""
                              v-model="customerForm.customerHomePhone"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <!-- <label class="form__label">Email address</label> -->
                            <div style="padding:0px !important" class="col-12">
                              <label style="padding:0px !important" class="form__label col-4">Email</label>
                              <label
                                v-if="requiredEmail"
                                style="padding:0px !important"
                                class="form__label col-8 text-danger"
                              >Email is required</label>
                            </div>
                            <input
                              type="email"
                              required
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter email address"
                              v-model="customerForm.customerEmailAddress"
                              @input="validateEmailCustomer(customerForm.customerEmailAddress)"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.customerEmailAddress,
                              }"
                            />
                            <p class="text--danger" v-if="customerErrorForm.customerEmailAddress">Please provide a valid email</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Mobile Phone2</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Mobile Phone2"
                              v-model="customerForm.customerMobilePhone2"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col-12">
                          <div class="form__item">
                            <label class="form__label">Home Address</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Home Address"
                              v-model="customerForm.customerHomeAddress"
                            />
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#cchomeaddresssection"
                    aria-expanded="false"
                    aria-controls="cchomeaddresssection"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Home Address Section
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="cchomeaddresssection" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">City</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter City"
                              v-model="customerForm.city"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.city,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">State/Province/Region</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter State/Province/Region"
                              v-model="customerForm.state"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.state,
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Zip Postal Code</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Zip Postal Code"
                              v-model="customerForm.zipPostalCode"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.zipPostalCode,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Country</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Country"
                              v-model="customerForm.country"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.country,
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form__item">
                            <label class="form__label">Street Address - Line 1</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Street Address Line 1"
                              v-model="customerForm.streetAddressLine1"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.streetAddressLine1,
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form__item">
                            <label class="form__label">Street Address - Line 2</label>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter Street Address Line 2"
                              v-model="customerForm.streetAddressLine2"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.streetAddressLine2,
                              }"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#customerIdDocs"
                    aria-expanded="false"
                    aria-controls="customerIdDocs"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Identification Documents
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="customerIdDocs"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div
                      class="row"
                      v-for="(
                        idTemp, index
                      ) in customerForm.identificationDocuments"
                      v-bind:key="
                        idTemp.identificationDocumentTemplateKey + '' + index
                      "
                    >
                      <div class="col-md-6">
                        Document Type: {{ idTemp.documentType }}
                      </div>
                      <div class="col-md-6">
                        Issuing Authority: {{ idTemp.issuingAuthority }}
                      </div>
                      <div class="col-md-6">
                        <div class="form__item">
                          <label>Document ID</label>
                          <input
                            type="text"
                            v-model="idTemp.documentId"
                            class="input form__input form__input--lg"
                            v-bind:class="{
                              'input-required':
                                idTemp.documentIdErr,
                            }"
                          />
                          <div class="error">
                            <span class="text-danger">
                              {{ idTemp.documentType }} document pattern is {{ idTemp.documentIdTemplate }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form__item">
                          <label>Valid Until</label>
                          <input
                            type="date"
                            :min="currDate"
                            v-model="idTemp.validUntil"
                            class="input form__input form__input--lg"
                            v-bind:class="{
                              'input-required':
                                idTemp.validUntilErr,
                            }"
                          />
                        </div>
                      </div>

                      <div v-if="idTemp.allowAttachments" class="col-12">
                        <div class="col-md-12">
                          <div class="form__item form__item_dottedbg">
                            <div class="p-5 text--center position-relative">
                              <input
                                :id="`attachment-${index}`"
                                type="file"
                                class="input form__input--lg"
                                accept="*"
                                ref="myFiles"
                                @change="onFileChange(index)"
                                hidden
                              />

                              <button
                                class="
                                  my-2
                                  button button--black
                                  text--500
                                  text--white
                                  text--small
                                  text--capital
                                "
                                @click.prevent="
                                  callFileUpload(`attachment-${index}`)
                                "
                              >
                                Choose File
                              </button>
                            </div>
                            <ul class="list-group">
                              <li class="list-group-item">
                                {{ idTemp.filename }} ({{ idTemp.fileSize | kb }} kb)
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="row align-items-center col-md-12" v-if="idTemp.showDoc">
                          <div class="col-10">
                            <div class="form__item">
                              <label 
                                @click.prevent="downloadAttachment(index, idTemp.attachments[0].documentDescription)"
                                class="download-attach"
                              >{{ idTemp.attachments[0].documentOriginalFileName }}</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form__item">
                              <button
                                class="
                                  button
                                  page__body__header__button
                                  modal__confirm__button--delete
                                  button--sm
                                  text--black
                                  w-100
                                  text--capital
                                "
                                @click.prevent="deleteAttachment(index, idTemp.attachments[0].documentDescription)"
                              >
                                <span>X</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form__item">
                            <button
                              :disabled="loading"
                              class="
                                my-2
                                button button--black
                                text--500 text--white text--small text--capital
                              "
                              @click.prevent="uploadFile(index)"
                            >
                              {{ loading ? "Uploading..." : "Upload File" }}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="col-md-12">
                          <div class="form__item">
                            <button
                              class="
                                button
                                page__body__header__button
                                ml-auto
                                button--grey
                                text--capital
                              "
                              type="button"
                              @click="idTemplateRemove('Customer', index)"
                            >
                              Remove Identification Document
                              <i class="ki ki-plus icon-sm ml-3"> </i>
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="pt-5">
                      <div class="form__item">
                        <label class="form__label">Select Template</label>
                        <select
                          class="select select--lg"
                          v-model="customerForm.selectedIdTemplate"
                        >
                          <option :value="null" disabled selected>
                            Select ID Doc
                          </option>
                          <option
                            v-for="item in idTemplates"
                            v-bind:key="item.identificationDocumentTemplateKey"
                            v-bind:value="
                              item.identificationDocumentTemplateKey
                            "
                          >
                            {{ item.documentType }}
                          </option>
                        </select>
                      </div>
                      <div class="form__item">
                        <button
                          class="
                            button
                            page__body__header__button
                            ml-auto
                            button--grey
                            text--capital
                          "
                          type="button"
                          @click="addIdTemplateToForm('Customer')"
                        >
                          Add Identification Document
                          <i class="ki ki-plus icon-sm ml-3"> </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#ccassociated"
                    aria-expanded="false"
                    aria-controls="ccassociated"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Associated
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="ccassociated"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Branch</label>
                            <select
                              class="select--lg w-100"
                              v-model="customerForm.assignedBranchKey"
                              @change="branchSelectChange($event)"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.assignedBranchKey,
                              }"
                            >
                              <option disabled value="null">
                                Select branch
                              </option>
                              <option
                                v-for="item in allBranches"
                                v-bind:key="item.branchKey"
                                v-bind:value="item.branchKey"
                              >
                                {{ item.branchName }} ({{ item.branchId }})
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Center</label>
                            <select
                              class="select--lg w-100"
                              v-model="customerForm.assignedCentreKey"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.assignedCentreKey,
                              }"
                            >
                              <option disabled value="null">
                                Select Center
                              </option>
                              <option
                                v-for="item in branchCenter"
                                v-bind:key="item.centerKey"
                                v-bind:value="item.centerKey"
                              >
                                {{ item.centerName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Account Officer</label>
                            <select
                              class="select--lg w-100"
                              v-model="customerForm.assignedUserKey"
                              v-bind:class="{
                                'input-required':
                                  customerErrorForm.assignedUserKey,
                              }"
                            >
                              <option disabled value="null">
                                Select Account Officer
                              </option>
                              <option
                                v-for="item in accountOfficers"
                                v-bind:key="item.userKey"
                                v-bind:value="item.userKey"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="modal__accordion"
                  v-for="(set, index) in customFields"
                  :key="set.customFieldSetKey"
                  v-show="customerForm.customerTypesKey"
                >
                  <li
                    v-if="set.customFields.length > 0"
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    :data-target="`#ccSet${index}`"
                    aria-expanded="false"
                    aria-controls="ccprofileNote"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      {{ set.customFieldSetName }}
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    :id="`ccSet${index}`"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-for="(form, i) in set.customFields"
                          :key="form.customFieldKey"
                          v-show="checkCustomFieldAvail(form)"
                        >
                          <div
                            class="form__item"
                          >
                            <label class="form__label">{{
                              form.customFieldName
                            }}</label>
                            <input
                              :type="
                                form.dataType === 'Number'
                                  ? 'number'
                                  : form.dataType === 'Date'
                                  ? 'date'
                                  : form.dataType === 'Checkbox'
                                  ? 'checkbox'
                                  : 'text'
                              "
                              v-if="(
                                form.dataType !== 'Selection' && 
                                form.dataType.toLowerCase() !== 'user link' &&
                                form.dataType.toLowerCase() !== 'group link' &&
                                form.dataType.toLowerCase() !== 'customer link'
                              )"
                              @input="editAccountValidateNumber(
                                form.customFieldValue,
                                form.dataType,
                                index,
                                i
                              )"
                              :id="'cus-' + form.customFieldKey"
                              :ref="form.customFieldKey"
                              v-model="form.customFieldValue"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              v-bind:class="{
                                'input-required':
                                  form.customFieldNameErr,
                              }"
                              :placeholder="`Enter ${form.customFieldName}`"
                            />
                            <select
                              class="select--lg w-100"
                              v-if="(
                                form.dataType === 'Selection' && 
                                form.dataType.toLowerCase() !== 'user link' &&
                                form.dataType.toLowerCase() !== 'group link' &&
                                form.dataType.toLowerCase() !== 'customer link'
                              )"
                              :id="'cus-' + form.customFieldKey"
                              :ref="form.customFieldKey"
                              v-model="form.customFieldValue"
                              v-bind:class="{
                                'input-required':
                                  form.customFieldNameErr,
                              }"
                            >
                              <option disabled selected value="null">
                                Select {{ form.customFieldName }}
                              </option>
                              <option
                                v-for="opt in form.customFieldSelections"
                                :key="opt.customFieldSelectionKey"
                                :value="opt.value"
                              >
                                {{ opt.value }}
                              </option>
                            </select>
                            <!-- User Multi select -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="'cus-' + form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="name"
                              track-by="userKey"
                              placeholder="Type to search"
                              open-direction="bottom"
                              :options="sortedUsers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="form.dataType.toLowerCase() === 'user link'"
                              v-bind:class="{
                                'input-required':
                                  form.customFieldNameErr,
                              }"
                            >
                              <template slot="tag" slot-scope="{ option, remove }">
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span class="custom__remove" @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Group multiselect -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="'cus-' + form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="groupName"
                              track-by="groupKey"
                              placeholder="Type to search"
                              open-direction="bottom"
                              :options="sortedGroups"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="form.dataType.toLowerCase() === 'group link'"
                              v-bind:class="{
                                'input-required':
                                  form.customFieldNameErr,
                              }"
                            >
                              <template slot="tag" slot-scope="{ option, remove }">
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span class="custom__remove" @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Customer multiselect -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="'cus-' + form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="customerFirstName"
                              track-by="customerKey"
                              placeholder="Type to search"
                              open-direction="bottom"
                              :options="sortedCustomers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="form.dataType.toLowerCase() === 'customer link'"
                              v-bind:class="{
                                'input-required':
                                  form.customFieldNameErr,
                              }"
                            >
                              <template slot="singleLabel" slot-scope="{ option }">
                                <span>{{ option.customerFirstName }}</span>
                                <span>&nbsp;{{ option.customerLastName }}</span>
                              </template>

                              <template slot="option" slot-scope="{ option }">
                                <div class="option__desc">
                                  <span class="option__title">
                                    {{ option.customerFirstName }}&nbsp;
                                  </span>
                                  <span class="option__small">{{
                                    option.customerLastName
                                  }}</span>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion" v-if="requiredEmployer">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#ccpEmployer"
                    aria-expanded="false"
                    aria-controls="ccpEmployer"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Employer
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="ccpEmployer"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <label
                          style="padding:0px !important"
                          class="form__label col-8 text-danger"
                        >Employer is required</label>
                        <multiselect
                          v-model="employer"
                          id="employer"
                          label="name"
                          track-by="employerKey"
                          placeholder="Select an employer"
                          selectLabel=""
                          open-direction="bottom"
                          :options="sortedEmployers"
                          :searchable="true"
                          :internal-search="true"
                          :clear-on-select="false"
                          :close-on-select="true"
                          :options-limit="300"
                          :limit="7"
                          :max-height="800"
                          :preselect-first="false"
                          @input="updateEmployer"
                        >
                          <template slot="tag" slot-scope="{ option }"
                            ><span class="custom__tag"
                              ><span>{{ option.name }}</span></span
                            ></template
                          >
                          <span slot="noResult">Oops! No result found.</span>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#ccprofileNote"
                    aria-expanded="false"
                    aria-controls="ccprofileNote"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Profile Note
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="ccprofileNote"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <textarea
                          cols="5"
                          rows="5"
                          class="input form__input"
                          placeholder="Profile Note"
                          v-model="customerForm.customerNotes"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    @click.prevent="saveCustomerForm"
                    v-if="!isSaving"
                  >
                    Save Customer
                  </button>

                  <!-- loader button -->
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="clearCustomCustomer"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <!-- Create Deposit Account -->
    <div
      class="modal modal__right"
      id="newDepositModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                class="modal__close modal__close--black"
                data-dismiss="modal"
                @click="removeNextDepositForm"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Creating New Deposit Account
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div v-show="depositForm">
                <div class="col-12 no--padding">
                  <div class="form__item">
                    <label class="form__label">Select Customer or Group</label>
                    <select
                      class="select select--lg"
                      required
                      v-model.trim="deposit.accountType"
                    >
                      <option value="" selected default>
                        Select account type
                      </option>
                      <option value="Customers">Customers</option>
                      <option value="Groups">Groups</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 no--padding">
                  <div class="form__item">
                    <label class="form__label" for="ajax"
                      >Search Customer/Group</label
                    >
                    <div v-if="deposit.accountType === 'Customers'">
                      <multiselect
                        v-model="selectedCustomer"
                        id="ajax"
                        label="customerNameWithID"
                        track-by="customerNameWithID"
                        placeholder="Type to display list"
                        open-direction="bottom"
                        :options="customers"
                        :multiple="false"
                        :searchable="true"
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :options-limit="300"
                        :show-labels="false"
                        :limit="3"
                        :limit-text="limitText"
                        :max-height="800"
                        :show-no-results="false"
                        :hide-selected="true"
                        @search-change="apifetch"
                        @input="getCustomerId"
                      >
                        <template slot="tag" slot-scope="{ option, remove }">
                          <span class="custom__tag">
                            <span>{{ option.customerNameWithID }}</span>
                            <span class="custom__remove" @click="remove(option)"
                              >❌</span
                            >
                          </span>
                        </template>
                        <span slot="noResult">Oops! No customers found. Consider changing the search query.</span>
                        <span slot="noOptions">Type to display list.</span>
                      </multiselect>
                    </div>
                    <div v-else>
                      <multiselect
                        v-model="selectedCustomer"
                        id="ajax"
                        label="groupName"
                        track-by="groupName"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="customers"
                        :multiple="false"
                        :searchable="true"
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="3"
                        :limit-text="limitText"
                        :max-height="800"
                        :show-no-results="false"
                        :hide-selected="true"
                        @search-change="apifetch"
                        @input="getCustomerId"
                      >
                        <template slot="tag" slot-scope="{ option, remove }">
                          <span class="custom__tag">
                            <span>{{ option.groupName }}</span>
                            <span class="custom__remove" @click="remove(option)"
                              >❌</span
                            >
                          </span>
                        </template>
                        <span slot="noOptions">Type to display list.</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-12 no--padding">
                  <div class="form__item">
                    <label class="form__label">Select Product</label>
                    <select
                      class="select select--lg"
                      required
                      v-model.trim="deposit.product"
                      @change="getFieldSets(deposit.product)"
                    >
                      <option value="" selected default>
                        Select account type
                      </option>
                      <option
                        v-bind:key="deposit.id"
                        v-for="deposit in deposits"
                        :id="deposit.id"
                        :value="deposit"
                      >
                        {{ deposit.productName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      data-dismiss="#newDepositModal"
                      type="button"
                      class="
                        button
                        form__button form__button--lg
                        w-30
                        save-changes
                      "
                      :disabled="!isInputFilled"
                      @click="depositAccountFormCreate"
                    >
                      continue
                    </button>
                    <a
                      role="button"
                      data-dismiss="modal"
                      class="
                        modal__close
                        form__action__text form__action__text--danger
                        text--capital
                        ml6
                      "
                      @click="removeNextDepositForm"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </div>
              <div v-show="nextDepositForm">
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Display Name</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        v-model="fetchDeposit.savingsAccountName"
                        placeholder="Enter Display Name"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Display ID</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        v-model="deposit.product.sampleIdGenerated"
                        placeholder="Enter Display ID"
                      />
                      <span>ID Template: {{ deposit.product.idTemplate }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Product Type</label>
                      <p
                        class="
                          text--black text--600 text--capital text--regular
                        "
                      >
                        {{ deposit.product.productType }}
                      </p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">currency</label>
                      <p
                        class="
                          text--black text--600 text--capital text--regular
                        "
                      >
                        {{ deposit.product.currency }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <p class="text--black text--600 text--capital text--small">
                    {{ deposit.product.productDescription }}
                  </p>
                </div>

                <ul id="accordion" class="mb5">
                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#accountTerms"
                      aria-expanded="false"
                      aria-controls="accountTerms"
                    >
                      <h5
                        class="
                          w-100
                          mb-0
                          d-flex
                          align-items-center
                          justify-content-between
                        "
                      >
                        Account Terms
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="accountTerms"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div v-show="fixedTermR" class="row">
                          <div class="col-12">
                            <div class="form__item">
                              <label class="form__label">Interest Rate</label>
                              <div v-if="interestError.length">
                                <div
                                  v-for="error in interestError"
                                  :key="error.id"
                                >
                                  <span
                                    class="
                                      text--tiny
                                      d-block
                                      text--left text--600
                                      mb-2
                                      text--danger
                                    "
                                    >{{ error }}</span
                                  >
                                </div>
                              </div>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span
                                    class="
                                      w-100
                                      d-flex
                                      text--medium text-white text--600
                                      align-items-center
                                      justify-content-center
                                    "
                                  >
                                    %
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  class="
                                    input input--right
                                    form__input form__input--lg
                                  "
                                  placeholder="Enter interest rate"
                                  :min="
                                    deposit.product.minInterestRateConstraints
                                  "
                                  :max="
                                    deposit.product.maxInterestRateConstraints
                                  "
                                  v-model.number="fetchDeposit.interestRate"
                                />
                              </div>
                              <small
                                class="
                                  text--tiny
                                  d-block
                                  text--right text--600
                                  mt-2
                                "
                                >Min:
                                {{ deposit.product.minInterestRateConstraints }}
                                Max:
                                {{ deposit.product.maxInterestRateConstraints }}
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                {{ deposit.product.interestChargeMethod }}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-6"
                            v-if="
                              deposit.product.calculationAccountBalance !== null
                            "
                          >
                            <div class="form__item">
                              <label class="form__label"
                                >Interest Calculated Using</label
                              >
                              <p
                                class="
                                  text--black
                                  text--600
                                  text--capital
                                  text--regular
                                "
                              >
                                {{ deposit.product.calculationAccountBalance }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-6"
                            v-if="deposit.product.maxTermLength !== 0"
                          >
                            <div class="form__item">
                              <label class="form__label"
                                >Maximum term length</label
                              >
                              <p
                                class="
                                  text--black
                                  text--600
                                  text--capital
                                  text--regular
                                "
                              >
                                {{ deposit.product.maxTermLength }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-6"
                            v-if="
                              deposit.product.interestPaymentPeriod !== null
                            "
                          >
                            <div class="form__item">
                              <label class="form__label"
                                >Interest paid into account</label
                              >
                              <p
                                class="
                                  text--black
                                  text--600
                                  text--capital
                                  text--regular
                                "
                              >
                                {{ deposit.product.interestPaymentPeriod }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6" v-if="!['Fixed Deposit', 'FixedDeposit'].includes(deposit.product.productType)">
                            <div class="form__item">
                              <label class="form__label"
                                >Maximum Withdrawal Amount</label
                              >
                              <input
                                type="text"
                                :class="[
                                    'input input--right',
                                    'form__input form__input--lg',
                                    numberIsValid(formattedWithAmount) ? 'input-required' : ''
                                  ]
                                "
                                min="0"
                                placeholder="Enter Maximum Withdrawal Amount"
                                :value="formattedWithAmount"
                                @input="numberIsValid(formattedWithAmount)"
                                @blur="formatAndValidateDepositWithdrawal($event)"
                              />
                            </div>
                          </div>

                          <div class="col-6" v-if="!['Fixed Deposit', 'FixedDeposit'].includes(deposit.product.productType)">
                            <div class="form__item">
                              <label class="form__label"
                                >Recommended Deposit Amount</label
                              >
                              <input
                                type="text"
                                :class="[
                                    'input input--right',
                                    'form__input form__input--lg',
                                    numberIsValid(formattedAmountPaid) ? 'input-required' : ''
                                  ]
                                "
                                placeholder="Enter Recommended Deposit"
                                :value="formattedAmountPaid"
                                @input="numberIsValid(formattedAmountPaid)"
                                @blur="fomartAndValidateDepositAmount($event)"
                              />
                              <p class="text-danger" v-if="defDepositAmount">{{ defDepositAmount }}</p>
                            </div>
                          </div>

                          <div class="col-12" v-if="['Fixed Deposit', 'FixedDeposit'].includes(deposit.product.productType)">
                            <div class="form__item">
                              <label class="form__label"
                                >Opening Balance</label
                              >
                              <input
                                type="text"
                                :class="[
                                  'input input--right',
                                  'form__input form__input--lg',
                                  openingBalanceErr ? 'input-required' : ''
                                ]"
                                placeholder="Enter Amount"
                                v-model="fetchDeposit.openingBalance"
                                @blur="fomartAndValidateDepositAmount($event)"
                              />
                            </div>
                            <small
                              class="
                                text--tiny
                                d-block
                                text--right text--600
                              "
                              style="margin-top:-36px;margin-bottom:4rem;"
                              >Min Open Balance:
                              {{ fetchDeposit.minOpeningBalance }} | 
                              Max Open Balance:
                              {{ fetchDeposit.maxOpeningBalance }}
                            </small>
                          </div>

                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Branch</label>
                              <multiselect
                                v-model="getAssignedBranchKey"
                                id="ajax"
                                label="branchName"
                                track-by="branchName"
                                placeholder="Type to search branch"
                                open-direction="bottom"
                                :options="branchList"
                                :multiple="false"
                                :searchable="true"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="300"
                                :limit="3"
                                :max-height="800"
                                :show-no-results="false"
                                :hide-selected="true"
                                @input="getAccountOfficer"
                                v-bind:class="{
                                  'input-required':
                                    depositErrorForm.assignedBranchKey,
                                }"
                              >
                                <template slot="tag" slot-scope="{ option, remove }">
                                  <span class="custom__tag">
                                    <span>{{ option.branchName }}</span>
                                    <span class="custom__remove" @click="remove(option)"
                                      >❌</span
                                    >
                                  </span>
                                </template>
                              </multiselect>
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Account Officer</label>
                              <multiselect
                                v-model="getAssignedUserKey"
                                id="ajax"
                                label="name"
                                track-by="name"
                                placeholder="Type to search officer"
                                open-direction="bottom"
                                :options="accountOfficers"
                                :multiple="false"
                                :searchable="true"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="300"
                                :limit="5"
                                :max-height="800"
                                :show-no-results="false"
                                :hide-selected="true"
                                @input="getAssignedUser"
                                v-bind:class="{
                                  'input-required':
                                    depositErrorForm.assignedUserKey,
                                }"
                              >
                                <template slot="tag" slot-scope="{ option, remove }">
                                  <span class="custom__tag">
                                    <span>{{ option.name }}</span>
                                    <span class="custom__remove" @click="remove(option)"
                                      >❌</span
                                    >
                                  </span>
                                </template>
                              </multiselect>
                            </div>
                          </div>

                          <div class="col-6" v-if="isSavingPlan">
                            <div class="form__item">
                              <label class="form__label"
                                >Saving Goal Target</label
                              >
                              <input
                                type="text"
                                class="
                                  input input--right
                                  form__input form__input--lg
                                "
                                min="0"
                                placeholder="Enter Recommended Deposit"
                                v-model.number="fetchDeposit.savingsGoalTarget"
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          class="form__item"
                          v-if="deposit.product.interestRateTerms !== null"
                        >
                          <label class="form__label">Interest Rate Terms</label>
                          <p
                            class="
                              text--black text--600 text--capital text--regular
                            "
                          >
                            {{ deposit.product.interestRateTerms }}
                          </p>
                        </div>

                        <div v-show="tierBalance" class="row">
                          <div class="col-12">
                            <div
                              class="form__item p-5 border border-radius"
                              v-for="(balanceTier, counter) in deposit.product
                                .tiers"
                              :key="counter"
                            >
                              <p
                                class="
                                  text--primary
                                  form__item_text
                                  text--regular
                                  mb-5
                                "
                              >
                                Tiered Per Balance {{ counter + 1 }}
                              </p>
                              <div class="row">
                                <div class="col-6">
                                  <label class="form__label"> From </label>
                                  <input
                                    type="number"
                                    placeholder="0.00"
                                    class="input form__input form__input--lg"
                                    v-model.number="balanceTier.fromTier"
                                    min="0.00"
                                    readonly
                                  />
                                </div>
                                <div class="col-6">
                                  <label class="form__label"> To </label>
                                  <input
                                    type="number"
                                    placeholder="0.00"
                                    class="input form__input form__input--lg"
                                    v-model.number="balanceTier.toTier"
                                    min="0.00"
                                    readonly
                                  />
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6">
                                  <div class="form__item">
                                    <label class="form__label">
                                      Amount for
                                      {{ deposit.product.interestChargeMethod }}
                                    </label>
                                    <input
                                      type="number"
                                      placeholder="Enter Amount"
                                      class="input form__input form__input--lg"
                                      v-model.number="
                                        balanceTier.interestTierValue
                                      "
                                      min="0"
                                      readonly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-show="tierPeriod" class="row">
                          <div class="col-12">
                            <div
                              class="form__item p-5 border border-radius"
                              v-for="(balanceTier, counter) in deposit.product
                                .tiers"
                              :key="counter"
                            >
                              <p
                                class="
                                  text--primary
                                  form__item_text
                                  text--regular
                                  mb-5
                                "
                              >
                                Tiered Per Period {{ counter + 1 }}
                              </p>
                              <div class="row">
                                <div class="col-6">
                                  <label class="form__label"> From </label>
                                  <input
                                    type="number"
                                    placeholder="0.00"
                                    class="input form__input form__input--lg"
                                    v-model.number="balanceTier.fromTier"
                                    min="0.00"
                                    readonly
                                  />
                                </div>
                                <div class="col-6">
                                  <label class="form__label"> To </label>
                                  <input
                                    type="number"
                                    placeholder="0.00"
                                    class="input form__input form__input--lg"
                                    v-model.number="balanceTier.toTier"
                                    min="0.00"
                                    readonly
                                  />
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6">
                                  <div class="form__item">
                                    <label class="form__label">
                                      Amount for
                                      {{ deposit.product.interestChargeMethod }}
                                    </label>
                                    <input
                                      type="number"
                                      placeholder="Enter Amount"
                                      class="input form__input form__input--lg"
                                      v-model.number="
                                        balanceTier.interestTierValue
                                      "
                                      min="0"
                                      readonly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Custom Field -->
                  <div
                    v-for="(cFields, index) in fetchDeposit.customFieldItems"
                    :key="cFields.customFieldSetKey"
                    v-show="deposit.product.id"
                    class="modal__accordion"
                  >
                    <li
                      v-if="cFields.customFields !== null"
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      :data-target="'#dynamic_' + cFields.customFieldSetKey"
                      aria-expanded="false"
                      :aria-controls="'dynamic_' + cFields.customFieldSetKey"
                    >
                      <h5
                        class="
                          w-100
                          mb-0
                          d-flex
                          align-items-center
                          justify-content-between
                        "
                      >
                        {{ cFields.customFieldSetName }}
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      :id="'dynamic_' + cFields.customFieldSetKey"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="row">
                          <div
                            class="col-6"
                            v-for="(form, i) in cFields.customFields"
                            :key="form.customFieldKey"
                          >
                            <div class="form__item" v-show="isDefault(form)">
                              <label class="form__label">{{
                                form.customFieldName
                              }}</label>
                              <input
                                :type="
                                  form.dataType === 'Number'
                                    ? 'number'
                                    : form.dataType === 'Date'
                                    ? 'date'
                                    : form.dataType === 'Checkbox'
                                    ? 'checkbox'
                                    : 'text'
                                "
                                v-if="(
                                  form.dataType !== 'Selection' &&
                                  form.dataType.toLowerCase() !== 'user link' &&
                                  form.dataType.toLowerCase() !== 'group link' &&
                                  form.dataType.toLowerCase() !== 'customer link'
                                )"
                                @input="
                                  depositValidateNumber(
                                    $event.target.value,
                                    form.dataType,
                                    index,
                                    i
                                  )
                                "
                                :id="form.customFieldKey"
                                :ref="form.customFieldKey"
                                class="
                                  input input--right
                                  form__input form__input--lg
                                "
                                :class="[
                                  'input input--right',
                                  'form__input form__input--lg',
                                  form.customFieldNameErr ? 'input-required' : ''
                                ]"
                                :placeholder="`Enter ${form.customFieldName}`"
                              />
                              <select
                                class="select--lg w-100"
                                v-if="(
                                  form.dataType === 'Selection' &&
                                  form.dataType.toLowerCase() !== 'user link' &&
                                  form.dataType.toLowerCase() !== 'group link' &&
                                  form.dataType.toLowerCase() !== 'customer link'
                                )"
                                :class="[
                                  'input input--right',
                                  'form__input form__input--lg',
                                  form.customFieldNameErr ? 'input-required' : ''
                                ]"
                                :id="form.customFieldKey"
                                :ref="form.customFieldKey"
                              >
                                <option disabled selected value="null">
                                  Select {{ form.customFieldName }}
                                </option>
                                <option
                                  v-for="opt in form.customFieldSelections"
                                  :key="opt.customFieldSelectionKey"
                                  :value="opt.value"
                                >
                                  {{ opt.value }}
                                </option>
                              </select>

                              <!-- User Multi select -->
                              <multiselect
                                v-model="form.customFieldValue"
                                :id="form.customFieldKey"
                                :ref="form.customFieldKey"
                                label="name"
                                track-by="userKey"
                                placeholder="Type to search user"
                                open-direction="bottom"
                                :options="sortedUsers"
                                :multiple="false"
                                :searchable="true"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="300"
                                :limit="3"
                                :max-height="800"
                                :show-no-results="false"
                                :hide-selected="true"
                                v-if="form.dataType.toLowerCase() === 'user link'"
                                :class="[
                                  'input input--right',
                                  'form__input form__input--lg',
                                  form.customFieldNameErr ? 'input-required' : ''
                                ]"
                              >
                                <template slot="tag" slot-scope="{ option, remove }">
                                  <span class="custom__tag">
                                    <span>{{ option.name }}</span>
                                    <span class="custom__remove" @click="remove(option)"
                                      >❌</span
                                    >
                                  </span>
                                </template>
                              </multiselect>

                              <!-- Group multiselect -->
                              <multiselect
                                v-model="form.customFieldValue"
                                :id="form.customFieldKey"
                                :ref="form.customFieldKey"
                                label="groupName"
                                track-by="groupKey"
                                placeholder="Type to search group"
                                open-direction="bottom"
                                :options="sortedGroups"
                                :multiple="false"
                                :searchable="true"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="300"
                                :limit="3"
                                :max-height="800"
                                :show-no-results="false"
                                :hide-selected="true"
                                v-if="form.dataType.toLowerCase() === 'group link'"
                                :class="[
                                  'input input--right',
                                  'form__input form__input--lg',
                                  form.customFieldNameErr ? 'input-required' : ''
                                ]"
                              >
                                <template slot="tag" slot-scope="{ option, remove }">
                                  <span class="custom__tag">
                                    <span>{{ option.name }}</span>
                                    <span class="custom__remove" @click="remove(option)"
                                      >❌</span
                                    >
                                  </span>
                                </template>
                              </multiselect>

                              <!-- Customer multiselect -->
                              <multiselect
                                v-model="form.customFieldValue"
                                :id="form.customFieldKey"
                                :ref="form.customFieldKey"
                                label="customerFirstName"
                                track-by="customerKey"
                                placeholder="Type to search customer"
                                open-direction="bottom"
                                :options="sortedCustomers"
                                :multiple="false"
                                :searchable="true"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="300"
                                :limit="3"
                                :max-height="800"
                                :show-no-results="false"
                                :hide-selected="true"
                                v-if="form.dataType.toLowerCase() === 'customer link'"
                                :class="[
                                  'input input--right',
                                  'form__input form__input--lg',
                                  form.customFieldNameErr ? 'input-required' : ''
                                ]"
                              >
                                <template slot="singleLabel" slot-scope="{ option }">
                                  <span>{{ option.customerFirstName }}</span>
                                  <span>&nbsp;{{ option.customerLastName }}</span>
                                </template>

                                <template slot="option" slot-scope="{ option }">
                                  <div class="option__desc">
                                    <span class="option__title">
                                      {{ option.customerFirstName }}&nbsp;
                                    </span>
                                    <span class="option__small">{{
                                      option.customerLastName
                                    }}</span>
                                  </div>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#decriptions"
                      aria-expanded="false"
                      aria-controls="decriptions"
                    >
                      <h5
                        class="
                          w-100
                          mb-0
                          d-flex
                          align-items-center
                          justify-content-between
                        "
                      >
                        Notes
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="decriptions"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="form__item">
                          <textarea
                            cols="5"
                            rows="5"
                            class="input form__input"
                            placeholder="Product Descriptions"
                            v-model.trim="fetchDeposit.savingsAccountNotes"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>

                <div class="form__item">
                  <div class="form__action">
                    <button
                      class="
                        button
                        form__button form__button--lg
                        w-30
                        save-changes
                      "
                      v-if="loading"
                    >
                      <div
                        class="spinner-border texxt-light"
                        role="status"
                      ></div>
                    </button>
                    <button
                      class="button form__button form__button--lg save-changes"
                      @click.prevent="createDepositAccount"
                      v-else
                    >
                      create deposit account
                    </button>
                    <a
                      @click="removeNextDepositForm()"
                      role="button"
                      data-dismiss="modal"
                      id="closeBtn"
                      class="
                        modal__close
                        form__action__text form__action__text--danger
                        text--capital
                        ml6
                      "
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Disbursement modal -->
    <div
      class="modal modal__confirm"
      id="disburseModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                Disbursement Account Number
              </span>
              <a
                data-dismiss="#disburseModal"
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
          </div>
          <div class="modal__content">
            <div class="modal__head border-bottom">
              <h5 class="modal__head__title text--capital">Add Field</h5>
            </div>
            <div class="modal__body">
              <form>
                <div class="form__item">
                  <label class="form__label">Field Name</label>
                  <select class="select select--lg" required>
                    <option value="" disabled selected>
                      Select Field Name
                    </option>
                    <option value="">Option 1</option>
                    <option value="">Option 2</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label">Type</label>
                  <p class="text--regular text--bold text--black text--capital">
                    Number
                  </p>
                </div>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      data-dismiss="#disburseModal"
                      type="button"
                      class="
                        button
                        form__button form__button--lg
                        w-30
                        save-changes
                      "
                    >
                      Add Field
                    </button>
                    <a
                      data-dismiss="#disburseModal"
                      role="button"
                      class="
                        modal__close
                        form__action__text form__action__text--danger
                        text--capital
                        ml6
                      "
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal :show="closeModal" @close="closeDeleteAttachmentModal">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              BVN validation failed.
            </h5>
            <p>
              BVN validation failed for this customer, click the continue button to create 
              the customer or click cancel button to stop the customer creation process.
            </p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--md
                  modal__confirm__button modal__confirm__button--cancel
                  mr-2
                "
                @click="closeDeleteAttachmentModal"
              >
                cancel
              </button>

              <!-- <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="isDeleting"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button> -->
              <button
                data-dismiss="#confirmModal"
                class="
                  button
                  form__button form__button--md
                  save-changes
                  modal__confirm__button--delete
                  fitrem
                "
                @click="approve"
              >
                continue
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <ChangePassword
      v-bind:show="showChangePasswordModal"
      @close="closeChangePasswordModal"
      @alertSuccess="alertSuccess"
      @alertError="alertError"
      @alertWarning="alertWarning"
      :user="user"
    />

    <CreateUser
      v-bind:show="showCreateModal"
      @close="closeCreateModal"
      @alertSuccess="successfullyCreatedUser"
      @alertError="alertError"
      @alertWarning="alertWarning"
      :getType="getType"
      :accessRightP="accessRight"
      :permissionP="permission"
      :transactionTypeP="transactionType"
      :role="allRole"
    />

    <logoutModal @alertError="alertError" />
  </header>
</template>

<script>
import Vue from "vue";
import moment from "moment"
import { mapGetters, mapState } from "vuex";
// import KTTopbar from "@/view/layout/header/Topbar.vue";
// import KTLayoutHeader from "@/assets/js/layout/base/header.js";
// import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
// import KTMenu from "@/view/layout/header/Menu.vue";
import userImg from "../../../assets/img/profile.svg";
import organisationBrandingService from "@/core/services/general-setup-service/organisation-branding.service.js";
import branchesService from "@/core/services/organization-service/branches.service";
import centresService from "@/core/services/organization-service/centres.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import groupService from "@/core/services/general-setup-service/group.service";
// import customerTypeService from "@/core/services/general-setup-service/customer-type.service";
import fieldSetService from "@/core/services/fieldset-service/fieldset.service";
import idTemplatesService from "@/core/services/general-setup-service/id-templates.service";
import Multiselect from "vue-multiselect";
import branchService from "@/core/services/organization-service/branches.service";
import CreateUser from "@/view/pages/Access/Users/create.vue";
import { GET_GROUP_TYPES } from "@/core/services/store/deposit.module"
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";

//API services
import ApiService from "@/core/services/api.service";
import AccessApiService from '@/core/services/access.service';
import GeneralApiService from '@/core/services/general.service';

import {
  GET_ALL_ROLE,
  GET_TYPE,
  GET_ACCESS_RIGHT,
  GET_PERMISSION,
  GET_TRANSACTION_TYPE,
} from "@/core/services/store/types";

import {
  GET_GROUPED_MENU,
  LOGOUT,
  GET_CUSTOMER_TYPES,
} from "@/core/services/store/types";

import ChangePassword from "@/view/pages/Access/Users/changePassword.vue";
import currencyMixin from "@/mixins/currencyMixin";
import { Role } from "@/core/services/role.js";

export default Vue.extend({
  mixins: [currencyMixin],
  name: "MainHeader",
  components: {
    Multiselect,
    CreateUser,
    DeleteModal,
    ChangePassword,
    logoutModal: () =>
      import(
        /* WebpackChunkName: "logoutModal" */ "@/view/layout/UserTimeout.vue"
      ),
  },
  data() {
    return {
      userImg,
      orgBranding: {
        iconImage: null,
        logoImage: null,
      },
      showCreateModal: false,
      // General Creation
      isSaving: false,
      loading: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      myFiles: null,
      filename: "",
      fileSize: "",
      // GroupCreation //
      allBranches: [],
      allCentres: [],
      allGroups: [],
      allGroupRoleNames: [],
      grNameDict: null,
      allCustomers: [],
      formattedAmountPaid: '',
      formattedWithAmount: '',
      groupForm: {
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [],
        memberKeySet: new Set(),
        memberNames: {},
        memberRoles: [],
        memberKeyArray: [],
      },
      groupErrorForm: {
        // assignedBranchKey: false,
        // assignedCentreKey: false,
        // assignedUserKey: false,
        clientRoleKey: false,
        groupEmailAddress: false,
        groupName: false,
        groupPhoneNumber: false,
        uniqueGroupId: false,
        loanCycle: false,
        groupMobileNumber: false,
        groupNotes: false,
        createdBy: false,
      },
      selectedCustomerKey: null,
      groupRoleAddModel: {
        groupRoleNameKey: null,
        clientKey: null,
      },
      // customerTypes: [],
      branchCenter: [],
      customerForm: {
        assignedBranchKey: null,
        assignedCentreKey: null,
        assignedUserKey: null,
        customerTypesKey: null,
        customerBirthDate: null,
        customerEmailAddress: null,
        customerFirstName: null,
        customerGender: null,
        customerHomePhone: null,
        uniqueCustomerId: null,
        bvn: null,
        customerLastName: null,
        customerMiddleName: null,
        customerMobilePhone1: null,
        customerMobilePhone2: null,
        customerNotes: null,
        preferredLanguage: "English",
        customFieldValues: [],
        identificationDocuments: [],
        selectedIdTemplate: null,
        hasValidateBvn: false,
        employeeKey: null,
        // customerHomeAddress: null,
        streetAddressLine1: null,
        streetAddressLine2: null,
        city: null,
        state: null,
        zipPostalCode: null,
        country: null,
      },
      depositErrorForm: {
        assignedBranchKey: false,
        assignedUserKey: false,
      },
      customerErrorForm: {
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        customerTypesKey: false,
        customerBirthDate: false,
        customerEmailAddress: false,
        customerFirstName: false,
        customerGender: false,
        customerHomePhone: false,
        uniqueCustomerId: false,
        bvn: false,
        customerLastName: false,
        customerMiddleName: false,
        customerMobilePhone1: false,
        customerMobilePhone2: false,
        customerNotes: false,
        preferredLanguage: false,
        employeeKey: false,
        streetAddressLine1: false,
        streetAddressLine2: false,
        city: false,
        state: false,
        zipPostalCode: false,
        country: false,
      },
      customFields: [],
      //deposit account
      nextDepositForm: false,
      selectedCustomer: "",
      customers: [],
      deposit: {
        accountType: "",
        accountName: "",
        product: [],
        customerId: "",
      },
      fetchProduct: {
        customerId: "",
        groupId: "",
        branchId: "",
      },
      fetchDeposit: {
        accountHolderKey: "",
        accountHolderType: "",
        accountType: "",
        assignedBranchKey: "",
        assignedCenterKey: "",
        assignedUserKey: "",
        currencyCode: "",
        interestSettingsKey: "",
        savingsAccountName: "",
        savingsAccountNotes: "",
        savingsProductKey: "",
        interestRate: "",
        maxWithdrawalAmount: "",
        defaultDepositAmount: "",
        savingsGoalTarget: 0,
        uniqueId: "",
        customFieldItems: "",
        customFields: {},
      },
      deposits: [],
      accountOfficers: [],
      allUsers: [],
      selectedUser: null,
      userLoading: false,
      depositForm: true,
      fixedTermR: false,
      tierBalance: false,
      tierPeriod: false,
      interestError: [],
      idTemplates: [],
      customFieldValue: "",
      branchList: [],
      searchValue: [],
      searchText: "",
      loadSearch: false,
      enabled: false,
      defDepositAmount: '',
      searchArea: "",
      requiredBVN: false,
      requiredEmail: false,
      requiredEmployer: false,
      currDate: moment().format('YYYY-MM-DD'),
      openingBalanceErr: false,
      getAssignedBranchKey: "",
      getAssignedUserKey: "",
      allActiveCustomers: {},
      allActiveGroups: {},
      limitValue: "",
      isDeleting: false,
      closeModal: false,
      allEmployers: [],
      employer: "",
      bvnInfo: "",
      internalControl: "",
      showChangePasswordModal: false,
    };
  },
  mounted() {
    this.getGroupedMenu();
    this.getAllUsers();
    this.getAllCustomers();
    this.getAllGroups();
    this.getCustomerTypes();
    this.listBranches().then((res) => {
      this.loadingBranchList = false;
      if (res.status === 200) {
        this.branchList = res.data.data.items;
      }
    });
    this.getAllRole();
    this.getGetType();
    this.getAccessRight();
    this.getPermission();
    this.getTransactionType();
    this.getGroupType();
    this.getInternalControl();
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    ...mapState({
      groupedMenu: (state) => state.views?.groupedMenu?.data || [],
      username: (state) => state.auth?.user?.userName || "",
      allCustomerTypes: (state) => state.deposit?.customerTypes?.data || [],
      customerTypes: (state) => state.deposit?.customerTypes?.data || [],
      customerClientTypes: (state) => state.deposit?.customerTypes?.data?.items,
      groupTypes: (state) => state.deposit?.groupTypes?.data?.items,
      sortCustomerType() {
        let sorted = this.customerClientTypes?.slice().sort((a, b) => {
          let textA = a.clientTypeName.toUpperCase();
          let textB = b.clientTypeName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }); 

        return sorted;
      },
      groupClientTypes: (state) =>
        state.deposit?.groupTypes?.data?.items?.filter(
          (x) => x.clientType == "Group"
        ),
      sortGroupType() {
        // let sorted = this.groupClientTypes.slice().sort((a, b) => {
        //   let textA = a.clientTypeName.toUpperCase();
        //   let textB = b.clientTypeName.toUpperCase();
        //   return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        // });
        let sorted = [];
        if (this.groupClientTypes) {
          sorted = this.groupClientTypes.slice().sort((a, b) => {
            let textA = a.clientTypeName.toUpperCase();
            let textB = b.clientTypeName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        }

        return sorted;
      },
      sortedEmployers() {
        const employerArray = this.allEmployers;
        return employerArray.sort((a, b) => a.name.localeCompare(b.name));
      },
      isLoadingCustomerTypesRed: (state) =>
        state.deposit?.loadingCustomerTypes || false,
      allRole: (state) => state.access?.allRole?.data || [],
      getType: (state) => state.access?.getType?.data || [],
      accessRight: (state) => state.access?.accessRight?.data || [],
      transactionType: (state) => state.access?.transactionType?.data || [],
      permission: (state) => state.access?.permission?.data || [],
      user: (state) => state.auth.user,
    }),
    userCanCreateDeposit() {
      const deposit = this.user.scopes.find(item => item.scopeName === Role.DepositAccount);
      const { permissionsList } = deposit;
      return permissionsList.CanCreateSavingsAccount;
    },
    userCanViewDeposits() {
      const deposit = this.user.scopes.find(item => item.scopeName === Role.DepositAccount);
      const { permissionsList } = deposit;
      return permissionsList.CanViewSavingsAccount;
    },
    userCanCreateLoan() {
      const loan = this.user.scopes.find(item => item.scopeName === Role.LoanAccounts);
      const { permissionsList } = loan;
      return permissionsList.CanCreateLoanAccount;
    },
    userCanViewLoans() {
      const loan = this.user.scopes.find(item => item.scopeName === Role.LoanAccounts);
      const { permissionsList } = loan;
      return permissionsList.CanViewLoanAccount;
    },
    userCanViewLoanTransactions() {
      const loan = this.user.scopes.find(item => item.scopeName === Role.LoanAccounts);
      const { permissionsList } = loan;
      return permissionsList.CanViewLoanTransactions;
    },
    userCanViewDepositTransactions() {
      const deposit = this.user.scopes.find(item => item.scopeName === Role.DepositAccount);
      const { permissionsList } = deposit;
      return permissionsList.CanViewSavingsTransactions;
    },
    userCanViewActivities() {
      const activity = this.user.scopes.find(item => item.scopeName === Role.Activity);
      const { permissionsList } = activity;
      return permissionsList.CanViewActivities;
    },
    userCanCreateCustomer() {
      const client = this.user.scopes.find(item => item.scopeName === Role.Client);
      const { permissionsList } = client;
      return permissionsList.CanCreateClient;
    },
    userCanViewCustomer() {
      const client = this.user.scopes.find(item => item.scopeName === Role.Client);
      const { permissionsList } = client;
      return permissionsList.CanViewClient;
    },
    userCanCreateGroup() {
      const group = this.user.scopes.find(item => item.scopeName === Role.Group);
      const { permissionsList } = group;
      return permissionsList.CanCreateGroup;
    },
    userCanViewGroups() {
      const group = this.user.scopes.find(item => item.scopeName === Role.Group);
      const { permissionsList } = group;
      return permissionsList.CanViewGroup;
    },
    userCanCreateUser() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Access);
      const { permissionsList } = user;
      return permissionsList.CanCreateUser;
    },
    userCanViewUsers() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Access);
      const { permissionsList } = user;
      return permissionsList.CanViewUser;
    },
    userCanViewViews() {
      const views = this.user.scopes.find(item => item.scopeName === Role.Views);
      const { permissionsList } = views;
      return permissionsList.CanViewViews;
    },
    userCanViewBranches() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Branch);
      const { permissionsList } = user;
      return permissionsList.CanViewBranch;
    },
    userCanViewCenters() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Centre);
      const { permissionsList } = user;
      return permissionsList.CanViewCentre;
    },
    sortedUsers() {
      const userArray = this.allUsers;
      return userArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedCustomers() {
      const userArray = this.allActiveCustomers;
      return userArray.sort(
        (a, b) =>
          `${a.customerFirstName} ${a.customerLastName}`.localeCompare(
            `${b.customerFirstName} ${b.customerLastName}`
          )
      );
    },
    sortedGroups() {
      const userArray = this.allActiveGroups;
      return userArray.sort((a, b) => a.groupName.localeCompare(b.groupName));
    },

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    isInputFilled() {
      return (
        this.selectedCustomer &&
        this.deposit.product &&
        this.deposit.accountType
      );
    },

    isSavingPlan() {
      return (
        this.deposit.product.productType === "Saving plan" ||
        this.deposit.product.productType === "Savings plan" ||
        this.deposit.product.productType === "SAVING PLAN" ||
        this.deposit.product.productType === "SAVINGPLAN"
      );
    },
  },
  watch: {
    updateLogo() {
      this.$on("image_uploaded", () => {
        this.reloadLogo();
      });
    },
    searchText: function (newValue) {
      if (newValue == "") {
        this.clearSearch();
      };
    },
  },
  methods: {
    goToLoan() {
      this.$router.push({ path: "/loan/account/create" });
    },
    openChangePasswordModal() {
      this.showChangePasswordModal = true;
    },
    closeChangePasswordModal(toLoginPage = false) {
      this.showChangePasswordModal = false;
      if (toLoginPage) {
        this.$store.dispatch(LOGOUT);
        this.$router.push("/auth/login");
      }
    },
    isDefault(field) {
      let check = field.customFieldLinks.find(
        (link) => link.entityLinkedKey === this.deposit.product.id
      );
      if (check) {
        return check.isDefault;
      }
      return false;
    },
    isDefaultGroup(field) {
      let check = field.customFieldLinks.find(
        (link) => link.entityLinkedKey === this.groupForm.clientRoleKey
      );
      if (check) {
        return check.isDefault;
      }
      return false;
    },
    async getAllUsers() {
      this.allUsers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageNumber: 1,
          pageSize: 1000000,
          branchKey: "",
          status: true,
          filters: []
        }
        AccessApiService.post('ProleanUser/GetUsersWithoutPermission', userModel)
        .then((resp) => {
          this.allUsers = resp.data.data;
          this.userLoading = false;
        })
      } catch(e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.alertError(error);
      }
    },
    getInternalControl() {
      GeneralApiService.get('InternalControls')
        .then((resp) => {
          this.internalControl = resp.data.data;
        });
    },
    async getAllCustomers() {
      this.allActiveCustomers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageIndex: 1,
          pageSize: 100000,
          filters: [],
          customerStatus: "Active"
        }
        GeneralApiService.post('Customer/customers', userModel)
        .then((resp) => {
          this.allActiveCustomers = resp.data.data.items;
          this.userLoading = false;
        })
      } catch(e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.alertError(error);
      }
    },
    async getAllGroups() {
      this.allActiveGroups = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageIndex: 1,
          pageSize: 1000000,
          filter: "",
          filters: []
        }
        GeneralApiService.post('Group/Groups', userModel)
        .then((resp) => {
          this.allActiveGroups = resp.data.data.items;
          this.userLoading = false;
        })
      } catch(e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.alertError(error);
      }
    },
    getAllRole() {
      this.$store.dispatch(GET_ALL_ROLE, {
        pageIndex: 1,
        pageSize: 1000,
        searchRoleName: "",
      });
    },
    numberIsValid(amount) {
      if(!this.isValidNumberInput(amount)) return true;
      return false;
    },
    numberIsValidFD() {
      if(!this.fetchDeposit.openingBalance) {
        this.alertWarning("Opening balance is required.");
        return true;
      };
      if(!isNaN(parseFloat(this.fetchDeposit.openingBalance)) && isFinite(this.fetchDeposit.openingBalance)) {
        if(parseFloat(this.fetchDeposit.openingBalance) < this.fetchDeposit.minOpeningBalance) {
          this.alertWarning("Opening Balance Amount cannot be less than the minimum opening balance value.");
          // this.fetchDeposit.openingBalance = this.fetchDeposit.minOpeningBalance;
          this.openingBalanceErr = true;
          return true;
        } else if(parseFloat(this.fetchDeposit.openingBalance) > this.fetchDeposit.maxOpeningBalance) {
          this.alertWarning("Opening Balance Amount cannot be greater than the maximum opening balance value.");
          // this.fetchDeposit.openingBalance = this.fetchDeposit.maxOpeningBalance;
          this.openingBalanceErr = true;
          return true;
        } else {
          this.openingBalanceErr = false;
          return false;
        }
      } else {
        this.alertWarning("Invalid input type, number required.");
        // this.fetchDeposit.openingBalance = this.fetchDeposit.minOpeningBalance;
        this.openingBalanceErr = true;
        return true;
      }
    },
    fomartAndValidateDepositAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
          this.fetchDeposit.defaultDepositAmount = amtPaid;
          this.formattedAmountPaid = amountInputted;
          return;
      }
      this.fetchDeposit.defaultDepositAmount = 0;
      this.formattedAmountPaid = '';
    },
    formatAndValidateDepositWithdrawal(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
          this.fetchDeposit.maxWithdrawalAmount = amtPaid;
          this.formattedWithAmount = amountInputted;
          return;
      }
      this.fetchDeposit.defaultDepositAmount = 0;
      this.formattedWithAmount = '';
    },
    getTransactionType() {
      this.$store.dispatch(GET_TRANSACTION_TYPE);
    },
    getGroupType() {
      this.$store.dispatch(GET_GROUP_TYPES);
    },
    getAccessRight() {
      this.$store.dispatch(GET_ACCESS_RIGHT);
    },
    getPermission() {
      this.$store.dispatch(GET_PERMISSION);
    },
    getGetType() {
      this.$store.dispatch(GET_TYPE);
    },
    successfullyCreatedUser(desc) {
      this.alertSuccess(desc);
      this.closeCreateModal();
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    async downloadAttachment(index, key) {
      await ApiService.getFormData(
        `GeneralDocument/DownloadDocument?documentKey=${key}`
      )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.customerForm.identificationDocuments[index].attachments.originalName || "file");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          this.alertError(error.response.data.message)
        });
    },
    deleteAttachment(index, key) {
      ApiService.delete(
        `GeneralDocument/delete?documentKey=${key}`
      )
        .then(() => {
          delete this.customerForm.identificationDocuments[index].attachments;
          this.customerForm.identificationDocuments[index].showDoc = false;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    listBranches() {
      this.loadingBranchList = true;
      return branchService.get({
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 100,
      });
    },
    getAccountOfficer(event) {
      this.fetchDeposit.assignedBranchKey = event.branchKey;
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${this.fetchDeposit.assignedBranchKey}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      })
    },
    getAssignedUser(event) {
      this.fetchDeposit.assignedUserKey = event.userKey;
    },
    getGroupAccountOfficer() {
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${this.groupForm.assignedBranchKey}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      })
    },
    getCustomerAccountOfficer(key) {
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${key}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      })
    },
    onFileChange(index) {
      this.myFiles = this.$refs.myFiles[index].files[0];
      this.customerForm.identificationDocuments[index].filename = this.$refs.myFiles[index].files[0].name;
      this.customerForm.identificationDocuments[index].fileSize = this.$refs.myFiles[index].files[0].size;
    },
    callFileUpload(id) {
      let input = document.getElementById(id);
      input.click();
    },
    uploadFile(index) {
      if(!this.customerForm.identificationDocuments[index].validUntil) {
        this.alertError("Document ID expiry date is required.");
        return;
      }
      const formData = new FormData();
      formData.append("uploadFile", this.myFiles);
      this.loading = true;
      ApiService.postFormData(
        `GeneralDocument/UploadAttachmentForCustomer`,
        formData
      )
        .then((resp) => {
          let desc = "File successully uploaded";
          this.customerForm.identificationDocuments[index].showDoc = true;
          this.customerForm.identificationDocuments[index].filename = '';
          this.customerForm.identificationDocuments[index].fileSize = 0;
          this.customerForm.identificationDocuments[index].attachments = [{
            documentDescription: resp.data.data.documentKey,
            documentFileSize:  resp.data.data.fileSize,
            documentName: resp.data.data.fileName,
            documentOriginalFileName: resp.data.data.originalName,
            documentType: resp.data.data.fileType,
            // documentLocation: resp.data.data.fileUrl,
            documentLocation: resp.data.data.filelocation,
          }];
          this.alertSuccess(desc);
          this.loading = false;
          this.filename = "";
          this.fileSize = "";
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
          this.loading = false;
        });
    },
    resetDepositForm() {
      this.deposit = {
        accountType: "",
        accountName: "",
        customerId: "",
        productName: "",
        product: [],
      };
      this.selectedCustomer = {};
    },
    getGroupedMenu() {
      this.$store.dispatch(GET_GROUPED_MENU);
    },
    logout() {
      this.$store.dispatch(LOGOUT);
      this.$router.push("/auth/login");
    },
    async getOrgBranding() {
      try {
        this.isLoading = true;
        const res = await organisationBrandingService.get();
        if (res.data.data != null) {
          this.orgBranding = res.data.data;
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getBranches() {
      try {
        this.isLoading = true;
        const res = await branchesService.get({
          branchName: null,
          branchState: "Active",
          sortColumn: "",
          isDescending: false,
          pageIndex: 1,
          pageSize: 100,
        });
        this.allBranches = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getCentres() {
      this.allCentres = [];
      try {
        this.isLoading = true;
        const res = await centresService.get({
          centreName: null,
          centreStatus: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
        });
        this.allCentres = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getCustomers() {
      try {
        this.isLoading = true;
        const res = await customerService.getCustomers({
          customerTypeKey: null,
          filters: [],
          pageIndex: 1,
          pageSize: 1000,
          customerStatus: null,
        });
        this.allCustomers = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        // const error = e.response.data.message;
        // this.alertError(error);
      }
    },
    async getCustomerTypes() {
      try {
        this.$store.dispatch(GET_CUSTOMER_TYPES);
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    validateBVN(bvn) {
      if(bvn) {
        if(bvn.length != 11){ 
          this.customerErrorForm.bvn = true
          this.alertWarning("BVN cannot be more/less than 11 characters.");
          return true;
        } else {
          this.customerErrorForm.bvn = false
          return false;
        }
      }
    },
    validateEmail(email) {
      let validRegex1 = /\S+@\S+\.\S+/;
      if(!email.trim().match(validRegex1)) {
        this.groupErrorForm.groupEmailAddress = true;
        return this.alertWarning('Please provide a valid email.');
      } else {
        this.groupErrorForm.groupEmailAddress = false;
      }
    },
    validateEmailCustomer(email) {
      let validRegex1 = /\S+@\S+\.\S+/;
      if(!email.trim().match(validRegex1)) {
        this.customerErrorForm.customerEmailAddress = true;
        return this.alertWarning('Please provide a valid email.');
      }

      this.customerErrorForm.customerEmailAddress = false;
    },
    async getGroupRoleNames() {
      try {
        this.isLoading = true;
        const res = await groupService.getGroupRoleNames({ pageSize: 100000, pageIndex: 1 });
        this.allGroupRoleNames = res.data.data.data;
        let dictionary = Object.assign(
          {},
          ...this.allGroupRoleNames.map((x) => ({
            [x.groupRoleNameKey]: x.groupRoleName,
          }))
        );
        this.grNameDict = dictionary;
        this.isLoading = false;
      } catch (e) {
        if(e.response) {
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    async getBranchCenters(key) {
      const model = {
        searchParam: null,
        branchKey: key,
        pageIndex: 1,
        pageSize: 100,
      };
      try {
        const res = await centresService.search(model);
        this.branchCenter = [];
        this.branchCenter = res.data.data.items;
        // this.isLoading = false;
      } catch (e) {
        // this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    groupValidateNumber(num, type, fieldSetIndex, fieldIndex) {
      if (type.toLowerCase() === "number") {
        if (isNaN(parseFloat(num))) {
          this.alertWarning("Invalid input value, Number required.");
          const key = this.customFields[fieldSetIndex].customFields[fieldIndex].customFieldKey;
          this.$refs[key][0].value = 0;
        }
      }
    },
    saveGroupForm: async function (e) {
      e.preventDefault();
      if(this.groupErrorForm.groupEmailAddress) {
        this.alertWarning('Please provide a valid email.');
        return;
      }
      if (this.validateGroupForm()) {
        try {
          this.isSaving = true;
          if (!this.groupForm.groupEmailAddress && this.requiredEmail) {
            this.alertWarning("Email field is required.");
            return;
          }
          this.groupForm.customFieldValues = [];
          let cForm = [];
          let isValidate = false
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                let key = form.customFieldKey;
                let value = this.$refs[key][0].value;

                if (
                  ['customer link', 'group link', 'user link'].includes(form.dataType.toLowerCase()) &&
                  value.length == 0
                ) {
                  value = "";
                }
                if (form.dataType.toLowerCase() === "checkbox") {
                  value = this.$refs[key][0].checked;
                }

                let required = form.customFieldLinks.find(
                  (item) => item.entityLinkedKey === this.groupForm.clientRoleKey
                );
                if (!value && required && required.isRequired) {
                  this.alertWarning(`Some custom field value are required`);
                  isValidate = true;
                  form.customFieldNameErr = true;
                  return;
                } else {
                  form.customFieldNameErr = false;
                }

                if (this.$refs[key].length && this.$refs[key][0].value && value) {
                  const formValObj = {
                    customFieldKey: key,
                    scoreAmount: 0,
                    indexInList: 0,
                    customFieldSetGroupIndex: 0,
                    customFieldValue: value.userKey || value.customerKey || value.groupKey || value,
                  };
                  cForm.push({ ...formValObj });

                } else {
                  this.groupForm.customFieldValues = []; 
                }
                this.groupForm.customFieldValues = cForm;
              });
            });
          }
          if (isValidate) {
            this.isSaving = false;
            return;
          }
          // this.groupForm.groupClientRoles = this.groupForm.memberRoles;
          this.groupForm.members = [];
          const memberRoleDict = {};
          this.groupForm.memberRoles.forEach((row) => {
            if (!memberRoleDict.hasOwnProperty(row.clientKey)) {
              memberRoleDict[row.clientKey] = [];
            }
            if (row.groupRoleNameKey != null) {
              memberRoleDict[row.clientKey].push(row.groupRoleNameKey);
            }
          });
          for (const clientKey in memberRoleDict) {
            this.groupForm.members.push({
              clientKey: clientKey,
              roleNameKeys: memberRoleDict[clientKey],
            });
          }

          const response = await groupService.createGroup(this.groupForm);
          this.clearGroupForm();
          this.isSaving = false;
          document.getElementById("createGroupCloseButton").click();
          this.alertSuccess("Group Created.");
          this.pushToGroupPage(response.data.data)
        } 
        catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    pushToGroupPage(data) {
      this.$router.push({ path: `/group/${data}` });
    },
    async addMemberToGroup(customerKey) {
      if (!this.groupForm.memberKeySet.has(customerKey)) {
        const c = this.allCustomers.filter((x) => x.customerKey == customerKey);
        if (c.length > 0) {
          const customer = c[0];
          let name = `${customer.customerNameWithID}`;

          this.groupForm.memberKeySet.add(customerKey);
          this.groupForm.memberNames[customerKey] = name;
          this.groupForm.memberRoles.push({
            groupRoleNameKey: null,
            groupRoleName: null,
            clientKey: customerKey,
            customerName: name,
          });
          this.groupForm.memberKeyArray = Array.from(
            this.groupForm.memberKeySet
          );
          this.selectedCustomerKey = '';
        }
      } else {
        this.alertWarning("Member name already exist.")
      }
    },
    deleteMemberFromGroup(item) {
      this.groupForm.memberKeySet.delete(item);
      delete this.groupForm.memberNames[item];
      this.groupForm.memberRoles = this.groupForm.memberRoles.filter(
        (x) => x.clientKey != item
      );
      this.groupForm.memberKeyArray = Array.from(this.groupForm.memberKeySet);
    },
    addGroupRole() {
      if(this.groupRoleAddModel.groupRoleNameKey && this.groupRoleAddModel.clientKey) {
        let groupRoleName = this.allGroupRoleNames.find(role => role.groupRoleNameKey === this.groupRoleAddModel.groupRoleNameKey);
        this.groupForm.memberRoles.forEach(item => {
          if(item.clientKey === this.groupRoleAddModel.clientKey) {
            item.groupRoleNameKey = this.groupRoleAddModel.groupRoleNameKey;
            item.groupRoleName = groupRoleName.groupRoleName;
          }
        })
        this.groupRoleAddModel.groupRoleNameKey = "";
        this.groupRoleAddModel.clientKey = "";
      }
    },
    updateEmployer() {
      this.customerForm.employeeKey = this.employer.employerKey;
    },
    async querySearch() {
      let searchQuery = this.searchText ? this.searchText : null;
      this.searchValue = [];
      this.loadSearch = true

      try {
        const response = await ApiService.post(`GlobalSearch`,
          { searchQuery, searchCategory: this.searchArea }
        )
        if (searchQuery == null) {
          this.clearSearch();
        } else {
          this.loadSearch = false;
          if(response.data.data) {
            this.searchValue = response.data.data;
          } else {
            this.alertWarning("Searched record does not exist.");
            this.searchValue = [];
          }
        }
      } catch(e) {
        this.searchValue = [];
        this.loadSearch = false;
      }
    },
    clearSearch() {
      this.loadSearch = false;
      this.searchValue = [];
      this.searchText = ""
    },
    openCustomer(key, type) {
      // const separate = this.$route.path.split('/')
      this.clearSearch()

      if(type == "customer") {
        this.$router.push({ path: `/customer/${key}` })
      }
      if(type == "group") {
        this.$router.push({ path: `/group/${key}` })
      }
      if(type == "deposit") {
        this.$router.push({ path: `/deposit-account/${key}` })
      }
      if(type == "loan") {
        this.$router.push({ path: `/loan/accounts/${key}` })
        // if(separate[1] == "loan" && separate[2] == "accounts" && separate[3]) {
        //   window.location.reload();
        // }
      }
    },
    removeGroupRole(clientKey, groupRoleNameKey) {
      // this.groupForm.memberRoles = this.groupForm.memberRoles.filter(
      //   (x) =>
      //     x.customerKey != clientKey && x.groupRoleNameKey != groupRoleNameKey
      // );
      let deleteRole = this.groupForm.memberRoles.find(
        (role) => role.clientKey == clientKey && role.groupRoleNameKey == groupRoleNameKey
      );
      deleteRole.groupRoleNameKey = null;
      deleteRole.groupRoleName = null;
      const savedRole = this.groupForm.memberRoles.filter(
        (x) =>
          x.clientKey != clientKey && x.groupRoleNameKey != groupRoleNameKey
      );
      this.groupForm.memberRoles = [...savedRole, deleteRole]
    },
    openGroupModal() {
      document.getElementById('createGroupModal').modal({backdrop: 'static', keyboard: false});
      this.clearGroupForm();
    },
    openDepositModal() {
      document.getElementById('newDepositModal').modal({backdrop: 'static', keyboard: false});
    },
    clearGroupForm() {
      this.requiredEmail = false;
      this.groupForm = {
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [],
        memberKeySet: new Set(),
        memberNames: {},
        memberRoles: [],
        memberKeyArray: [],
      };
      this.groupRoleAddModel = {
        groupRoleNameKey: null,
        clientKey: null,
      };
      this.groupForm.customFieldValues = [];
      this.getCustomFormFields("Groups", null);
    },
    clearCustomCustomer() {
      if (this.customFields.length > 0) {
        this.customFields.forEach((formSet) => {
          formSet.customFields.forEach((form) => {
            form.customFieldValue = "";
          });
        });
      }
    },
    validateGroupForm() {
      this.clearGroupValidation();
      let validateGroupRoleMember = []
      this.groupForm.memberRoles.forEach(item => {
        if(!item.groupRoleName && !item.groupRoleNameKey) {
          validateGroupRoleMember.push(false);
        } else {
          validateGroupRoleMember.push(true);
        }
      });

      const assignedBranchKey = this.internalControl.assignmentConstraints.branch
        ? this.groupForm.assignedBranchKey
        : true;
      const assignedCentreKey = this.internalControl.assignmentConstraints.centre
        ? this.groupForm.assignedCentreKey
        : true;
      const assignedUserKey = this.internalControl.assignmentConstraints.accountOfficer
        ? this.groupForm.assignedUserKey
        : true;

      if (
        this.groupForm.groupName &&
        this.groupForm.uniqueGroupId &&
        this.groupForm.clientRoleKey &&
        validateGroupRoleMember.includes(true) &&
        this.groupForm.groupPhoneNumber &&
        assignedBranchKey &&
        assignedCentreKey &&
        assignedUserKey
      ) {
        return true;
      }

      if (!this.groupForm.groupName) {
        this.groupErrorForm.groupName = true;
      }
      if (!this.groupForm.uniqueGroupId) {
        this.groupErrorForm.uniqueGroupId = true;
      }
      if (!this.groupForm.groupEmailAddress) {
        this.groupErrorForm.groupEmailAddress = true;
      }
      if(!this.groupForm.groupPhoneNumber) {
        this.groupErrorForm.groupPhoneNumber = true;
      }
      if (!this.groupForm.clientRoleKey) {
        this.groupErrorForm.clientRoleKey = true;
      }
      if(!validateGroupRoleMember.includes(true)) {
        this.groupErrorForm.groupRole = true;
      }
      if (!assignedBranchKey) {
        this.groupErrorForm.assignedBranchKey = true;
      }
      if (assignedBranchKey) {
        if (!assignedCentreKey) {
          this.groupErrorForm.assignedCentreKey = true;
        }
      }
      if (assignedBranchKey) {
        if (!assignedUserKey) {
          this.groupErrorForm.assignedUserKey = true;
        }
      }
      // if (!this.groupForm.assignedBranchKey) {
      //   this.groupErrorForm.assignedBranchKey = true;
      // }
      // if (!this.groupForm.assignedCentreKey) {
      //   this.groupErrorForm.assignedCentreKey = true;
      // }
      // if (!this.groupForm.assignedUserKey) {
      //   this.groupErrorForm.assignedUserKey = true;
      // }
      return false;
    },
    clearGroupValidation() {
      this.groupErrorForm = {
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        clientRoleKey: false,
        groupEmailAddress: false,
        groupName: false,
        groupPhoneNumber: false,
        uniqueGroupId: false,
        loanCycle: false,
        groupMobileNumber: false,
        groupNotes: false,
        createdBy: false,
        groupRole: false
      };
    },
    reloadLogo: function () {
      this.getOrgBranding();
    },
    // checkDocumentExpiryDate() {
    //   let check = false;
    //   for(let i=0; i<this.customerForm.identificationDocuments.lenght;i++) {
    //     if(!this.customerForm.identificationDocuments[i].validUntil) {
    //       check = true
    //       return;
    //     }
    //   }
    //   return check;
    // },
    limitBVNEntry(event) {
      const { value } = event.target;
      if (value.length > 11) {
        this.customerForm.bvn = this.limitValue;
      } else {
        this.limitValue = value;
      }
    },
    validateBVNRequest() {
      if (this.limitValue.length === 11) {
        this.isDeleting = true
        GeneralApiService.post(`Bvn/ValidateBvn?bvn=${this.limitValue}`)
          .then((res) => {
            this.customerForm.hasValidateBvn = true;
            this.bvnInfo = res.data.data.data;
            this.isDeleting = false;
          })
          .catch(() => {
            this.closeModal = true;
            this.isDeleting = false;
          });
      }
    },
    closeDeleteAttachmentModal() {
      this.closeModal = false;
      document.getElementById("customerFormCloseButton").click();
      this.clearCustomCustomer();
    },
    approve() {
      this.closeModal = false;
      this.customerForm.hasValidateBvn = false;
    },
    editAccountValidateNumber(num, type, fieldSetIndex, fieldIndex) {
      if (type.toLowerCase() === "number") {
        if (isNaN(parseFloat(num))) {
          this.alertWarning("Invalid input value, Number required.");
          this.customFields[fieldSetIndex].customFields[fieldIndex].customFieldValue = 0;
        }
      }
    },
    saveCustomerForm: async function (e) {
      e.preventDefault();
      if (this.validateCustomerForm()) {
        if (this.requiredBVN && !this.customerForm.bvn) {
          this.alertWarning("BVN field is required.");
          return;
        }
        if(this.validateBVN(this.customerForm.bvn)) {
          return;
        }
        if (!this.customerForm.customerEmailAddress && this.requiredEmail) {
          this.alertWarning("Email field is required.");
          return;
        }
        if (this.customerErrorForm.customerEmailAddress) {
         this.alertWarning('Please provide a valid email.');
         return;
        }
        if (this.requiredEmployer && !this.customerForm.employeeKey) {
          this.alertWarning("Employer is required.");
          return;
        }
        let check = false
        if (this.customerForm.identificationDocuments.length > 0) {
          this.customerForm.identificationDocuments.forEach((item) => {
            if (!item.documentId) {
              check = true;
              item.documentIdErr = true;
            } else {
              item.documentIdErr = false;
            }
            if (!item.validUntil) {
              check = true;
              item.validUntilErr = true;
            } else {
              item.validUntilErr = false;
            }
          })
        }
        if (check) {
          this.alertWarning("Documents ID and Valid Unit are required.");
          return;
        }
        this.isSaving = true;
        let isValidate = false;
        try {
          this.customerForm.customFieldValues = [];
          let cForm = [];
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                let required = form.customFieldLinks.find(
                  (item) => item.entityLinkedKey === this.customerForm.customerTypesKey
                )
                if (!form.customFieldValue && required && required.isRequired) {
                  this.alertWarning(`Some custom field value are required.`);
                  form.customFieldNameErr = true;
                  isValidate = true;
                  return;
                } else {
                  form.customFieldNameErr = false;
                }
                if (form.customFieldValue) {
                  let key = form.customFieldKey;

                  let value = "";

                  if (form.dataType.toLowerCase() === "group link") {
                    value = form.customFieldValue.groupKey;
                  } else if (form.dataType.toLowerCase() === "user link") {
                    value = form.customFieldValue.userKey;
                  } else if (form.dataType.toLowerCase() === "customer link") {
                    value = form.customFieldValue.customerKey;
                  } else {
                    value = form.customFieldValue;
                  }

                  const formValObj = {
                    // name: form.customFieldName,
                    customFieldKey: key,
                    customFieldValue: value,
                    scoreAmount: 0,
                    indexInList: 0,
                    customFieldSetGroupIndex: 0,
                  };
                  cForm.push({ ...formValObj });

                  this.customerForm.customFieldValues = cForm;
                }
              });
            });
          }
          if (isValidate) {
            this.isSaving = false;
            return;
          }
          if (this.customerForm.identificationDocuments.length) {
            const identificationDocuments = this.customerForm.identificationDocuments.map(item => ({
              attachments: item.attachments,
              documentId: item.documentId,
              documentType: item.documentType,
              documentIdTemplate: item.documentIdTemplate,
              identificationDocumentTemplateKey: item.identificationDocumentTemplateKey,
              issuingAuthority: item.issuingAuthority,
              validUntil: item.validUntil
            }));
            this.customerForm.identificationDocuments = [...identificationDocuments];
          }
          const res = await customerService.createCustomer(this.customerForm);
          if (res.data.status) {
            document.getElementById("customerFormCloseButton").click();
            this.isSaving = false;
            this.alertSuccess(res.data.message);
            // this.alertSuccess("Customer created successfully.");
            this.clearCustomCustomer();
            this.pushToCustomerPage(res.data.data);
            this.limitValue = "";
          } else {
            this.isSaving = false;
            this.alertError(res.data.message);
          }
        } catch (e) {
          this.isSaving = false;
          const error = e?.response?.data?.message;
          if(e.response.status == 409) {
            this.alertWarning(e.response.data);
          } else {
            this.alertError(error);
          }
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    pushToCustomerPage(data) {
      this.$router.push({ path: `/customer/${data}` });
    },
    preOpenCustomerForm(customerTypeKey = null) {
      this.clearCustomerForm();
      document.getElementById('createCustomer').modal({backdrop: 'static', keyboard: false});
      this.customerForm.customerTypesKey = customerTypeKey;
      this.getCustomFormFields("Customers", customerTypeKey);
    },
    clearCustomerForm: function () {
      this.requiredBVN = false;
      this.requiredEmail = false;
      this.requiredEmployer = false;
      this.bvnInfo = "";
      this.customerForm = {
        assignedBranchKey: null,
        assignedCentreKey: null,
        assignedUserKey: null,
        customerTypesKey: null,
        customerBirthDate: null,
        customerEmailAddress: null,
        customerFirstName: null,
        customerGender: null,
        customerHomePhone: null,
        uniqueCustomerId: null,
        bvn: null,
        customerLastName: null,
        customerMiddleName: null,
        customerMobilePhone1: null,
        customerMobilePhone2: null,
        customerNotes: null,
        preferredLanguage: "English",
        customFieldValues: [],
        identificationDocuments: [],
        selectedIdTemplate: null,
        employeeKey: null,
        hasValidateBvn: false,
        // customerHomeAddress: null,
        streetAddressLine1: null,
        streetAddressLine2: null,
        city: null,
        state: null,
        zipPostalCode: null,
        country: null,
      };
    },
    generateCustomerId(key) {
      this.customerForm.uniqueCustomerId = "";
      GeneralApiService.get(
        "Customer/GeneratedCustomersUniqueId",
        key
      )
        .then((response) => {
          this.customerForm.uniqueCustomerId = response.data.data;
        })
        .catch(() => {
          // console.log(err)
          this.customerForm.uniqueCustomerId = null;
        });
    },
    generateGroupId(key) {
      this.groupForm.uniqueGroupId = null;
      GeneralApiService.get(
        `Group/GetUniqueIdForGroup?groupTypeKey=${key}`)
        .then((response) => {
          this.groupForm.uniqueGroupId = response.data.message;
        })
        .catch(() => {
          this.groupForm.uniqueGroupId = "";
        });
    },
    validateCustomerForm() {
      this.clearCustomerValidation();

      const assignedBranchKey = this.internalControl.assignmentConstraints.branch
        ? this.customerForm.assignedBranchKey
        : true;
      const assignedCentreKey = this.internalControl.assignmentConstraints.centre
        ? this.customerForm.assignedCentreKey
        : true;
      const assignedUserKey = this.internalControl.assignmentConstraints.accountOfficer
        ? this.customerForm.assignedUserKey
        : true;

      if (
        this.customerForm.customerFirstName &&
        this.customerForm.customerLastName &&
        this.customerForm.uniqueCustomerId &&
        this.customerForm.customerBirthDate &&
        this.customerForm.customerGender &&
        this.customerForm.customerMobilePhone1 &&
        assignedBranchKey &&
        assignedCentreKey &&
        assignedUserKey
      ) {
        return true;
      }

      if (!this.customerForm.customerFirstName) {
        this.customerErrorForm.customerFirstName = true;
      }
      if (!this.customerForm.customerLastName) {
        this.customerErrorForm.customerLastName = true;
      }
      if (!this.customerForm.uniqueCustomerId) {
        this.customerErrorForm.uniqueCustomerId = true;
      }
      if (!this.customerForm.customerBirthDate) {
        this.customerErrorForm.customerBirthDate = true;
      }
      if (!this.customerForm.customerMobilePhone1) {
        this.customerErrorForm.customerMobilePhone1 = true;
      }
      if (!this.customerForm.customerGender) {
        this.customerErrorForm.customerGender = true;
      }
      if (!this.customerForm.customerEmailAddress) {
        this.customerErrorForm.customerEmailAddress = true;
      }
      // if (!this.customerForm.assignedBranchKey) {
      //   this.customerErrorForm.assignedBranchKey = true;
      // }
      // if (this.customerForm.assignedBranchKey) {
      //   if (!this.customerForm.assignedCentreKey) {
      //     this.customerErrorForm.assignedCentreKey = true;
      //   }
      // }
      // if (this.customerForm.assignedBranchKey) {
      //   if (!this.customerForm.assignedUserKey) {
      //     this.customerErrorForm.assignedUserKey = true;
      //   }
      // }
      if (!assignedBranchKey) {
        this.customerErrorForm.assignedBranchKey = true;
      }
      if (assignedBranchKey) {
        if (!assignedCentreKey) {
          this.customerErrorForm.assignedCentreKey = true;
        }
      }
      if (assignedBranchKey) {
        if (!assignedUserKey) {
          this.customerErrorForm.assignedUserKey = true;
        }
      }
      if (!this.customerForm.employeeKey) {
          this.customerErrorForm.employeeKey = true;
      }

      return false;
    },
    clearCustomerValidation() {
      this.customerErrorForm = {
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        customerTypesKey: false,
        customerBirthDate: false,
        customerEmailAddress: false,
        customerFirstName: false,
        customerGender: false,
        customerHomePhone: false,
        uniqueCustomerId: false,
        bvn: false,
        customerLastName: false,
        customerMiddleName: false,
        customerMobilePhone1: false,
        customerMobilePhone2: false,
        customerNotes: false,
        preferredLanguage: false,
        employeeKey: false,
        streetAddressLine1: false,
        streetAddressLine2: false,
        city: false,
        state: false,
        zipPostalCode: false,
        country: false,
      };
    },
    branchSelectChange(event) {
      const branchKey = event.target.value;
      this.getCustomerAccountOfficer(branchKey);
      this.getBranchCenters(branchKey);
    },
    addIdTemplateToForm(form) {
      if (form == "Customer") {
        let templateKey = this.customerForm.selectedIdTemplate;
        let idtemp = this.idTemplates.find(
          (x) => x.identificationDocumentTemplateKey == templateKey
        );
        if (idtemp != null) {
          let toPush = {};
          Object.assign(toPush, idtemp);
          toPush.documentId = "";
          toPush.validUntil = "";
          toPush.filename = '';
          toPush.fileSize = 0;
          toPush.showDoc = false;

          this.customerForm.identificationDocuments.push(toPush);
        }
      }
    },
    idTemplateRemove(form, index) {
      if (form == "Customer") {
        this.customerForm.identificationDocuments.splice(index, 1);
      }
    },
    captureCustomFieldValues() {
      let fieldValues = [];
      this.customFields.forEach((formSet) => {
        formSet.customFields.forEach((form) => {
          let key = form.customFieldKey;
          let value = document.getElementById(key).value;

          const formValObj = {
            customFieldKey: key,
            value,
          };
          fieldValues.push(formValObj);
        });
      });
      return fieldValues;
    },
    // autoGenerateId(type, key) {
    //   if (type === "Customers" && key !== null) {
    //     this.requiredBVN = this.customerClientTypes.find(item => key === item.customerTypeKey).requireBvn
    //     this.generateCustomerId(key);
    //   } else if (type === "Groups" && key !== null) {
    //     this.generateGroupId(key);
    //   }
    // },
    // Form inti
    async getCustomFormFields(type, key) {
      this.customFields = [];
      try {
        if (type === "Customers" && key !== null) {
          this.requiredBVN = this.customerClientTypes.find(item => key === item.customerTypeKey).requireBvn
          this.requiredEmployer = this.customerClientTypes.find(item => key === item.customerTypeKey).requireEmployer
          this.requiredEmail = this.customerClientTypes.find(item => key === item.customerTypeKey).requireEmailAddress;
          this.generateCustomerId(key);
        } else if (type === "Groups" && key !== null) {
          this.generateGroupId(key);
          this.requiredEmail = this.groupTypes.find(item => key === item.customerTypeKey).requireEmailAddress;
        }
        this.isLoading = true;
        const model = {
          customFieldSetType: type,
          entityLinkedKey: key,
        };
        const res = await fieldSetService.getFormFieldsByType(model);
        this.customFields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    validateMultipleCustomer(key) {
      this.enabled = true;
      GeneralApiService.post(
        `Group/FindIfCustomerCanBeAddedToMultipleGroup?customerKey=${key}`)
        .then((response) => {
          if(response.data.status) {
            this.enabled = false;
            this.addMemberToGroup(key)
          } else {
            this.enabled = false;
            this.selectedCustomerKey = ""
            this.alertError("This customer already belongs to another group and cannot be added.")
          }
        })
        .catch(() => {
          this.enabled = false;
          this.selectedCustomerKey = ""
          this.alertError("This customer already belongs to another group and cannot be added.")
        });
    },
    checkCustomFieldAvail(customField) {
      if (this.customerForm.customerTypesKey !== null) {
        let visible;
        visible = customField.customFieldLinks.find(
          (x) => x.entityLinkedKey == this.customerForm.customerTypesKey
        );
        return visible ? visible.isDefault : false;
      } else {
        return false;
      }
    },
    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    // reloadLogo: function() {
    //   this.getOrgBranding();
    // },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    limitText(count) {
      return `and ${count} other customers`;
    },
    apifetch(query) {
      if (this.deposit.accountType === "Customers") {
        GeneralApiService.get(
          `Customer/search/${query}`
        ).then((resp) => {
          this.customers = resp.data.data.items;
        });
      } else if (this.deposit.accountType === "Groups") {
        GeneralApiService.get(
          `Customer/search/${query}/group`
        ).then((resp) => {
          this.customers = resp.data.data.groups;
          this.groups = resp.data.data.customers;
        });
      }
    },
    clearAll() {
      this.selectedCustomer = "";
    },
    getCustomerId() {
      this.deposits = [];
      this.deposit.customerId = this.selectedCustomer.customerKey;
      this.fetchProduct.customerId = this.selectedCustomer.customerKey;
      this.fetchProduct.branchId = this.selectedCustomer.assignedBranchKey;
      this.fetchProduct.groupId = this.selectedCustomer.groupKey;
      ApiService.post(
        "DepositAdministration/FetchDepositProductsByCustomerOrGroup",
        this.fetchProduct
      )
        .then((resp) => {
          this.deposits = resp.data.data;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    depositAccountFormCreate() {
      this.depositForm = false;
      this.nextDepositForm = true;

      if (this.deposit.product.interestRateTerms === "Fixed") {
        this.fixedTermR = true;
      } else if (
        this.deposit.product.interestRateTerms === "Tier per balance" ||
        this.deposit.product.interestRateTerms === "Tier Per Balance"
      ) {
        this.tierBalance = true;
      } else if (
        this.deposit.product.interestRateTerms === "Tier per period" ||
        this.deposit.product.interestRateTerms === "Tier Per Period"
      ) {
        this.tierPeriod = true;
      } else {
        this.fixedTermR = false;
        this.tierBalance = false;
        this.tierPeriod = false;
      }
      this.fetchDeposit.accountHolderKey =
        this.selectedCustomer.customerKey || this.selectedCustomer.groupKey;
      this.fetchDeposit.accountHolderType = this.deposit.accountType;
      this.fetchDeposit.accountType = this.deposit.product.productType;
      // this.fetchDeposit.assignedBranchKey =
      //   this.selectedCustomer.assignedBranchKey;
      this.fetchDeposit.assignedBranchKey = "";
      this.fetchDeposit.assignedCenterKey =
        this.selectedCustomer.assignedCentreKey;
      this.fetchDeposit.assignedUserKey = "";
      this.fetchDeposit.currencyCode = this.deposit.product.currency;
      this.fetchDeposit.interestSettingsKey =
        this.deposit.product.interestSavingsKey;
      // this.fetchDeposit.savingsAccountName = this.deposit.product.productName;
      this.fetchDeposit.savingsAccountName =
        this.selectedCustomer.customerNameWithID || this.selectedCustomer.groupName;
      this.fetchDeposit.savingsProductKey = this.deposit.product.id;
      this.fetchDeposit.maxWithdrawalAmount =
        this.deposit.product.maxWithdrawalAmount;
      this.fetchDeposit.openingBalance = this.deposit.product.minOpeningBalance,
      this.fetchDeposit.maxOpeningBalance = this.deposit.product.maxOpeningBalance,
      this.fetchDeposit.minOpeningBalance = this.deposit.product.minOpeningBalance,
      this.fetchDeposit.defaultDepositAmount =
        this.deposit.product.defaultDepositAmount;
      this.formattedWithAmount = this.inputCurrencyFormat(this.deposit.product.maxWithdrawalAmount);
      this.formattedAmountPaid = this.inputCurrencyFormat(this.deposit.product.defaultDepositAmount);
    },
    viewRoute(data) {
      this.$router.push({ path: `/deposit-account/${data}` });
      // this.$router.push({ path: `/deposit-account/${data}` }, () =>
      //   window.location.reload()
      // );
    },
    getAllEmployers() {
      const paged = {
        pageIndex: 1,
        pageSize: 99999,
      };

      GeneralApiService.post(
        "Employer/Employers",
        paged
      )
        .then((res) => res.data.data.items)
        .then((res) => {
          this.allEmployers = res;
        });
    },
    validateBranchAndOfficer() {
      this.depositErrorForm = {
        assignedBranchKey: false,
        assignedUserKey: false,
      }

      if (this.fetchDeposit.assignedUserKey && this.fetchDeposit.assignedBranchKey) {
        return true
      }

      if (this.fetchDeposit.assignedUserKey) {
        this.depositErrorForm.assignedUserKey = true;
      }
      if (this.fetchDeposit.assignedBranchKey) {
        this.depositErrorForm.assignedBranchKey = true;
      }

      return false
    },
    depositValidateNumber(num, type, fieldSetIndex, fieldIndex) {
      if (type.toLowerCase() === "number") {
        if (isNaN(parseFloat(num))) {
          this.alertWarning("Invalid input value, Number required.");
          const key = this.fetchDeposit.customFieldItems[fieldSetIndex].customFields[fieldIndex].customFieldKey;
          this.$refs[key][0].value = 0;
        }
      }
    },
    createDepositAccount(e) {
      e.preventDefault();
      if(!this.validateBranchAndOfficer()) {
        this.alertWarning("Branch and Account officer is required.");
        return;
      }
      this.fetchDeposit.uniqueId = this.deposit.product.sampleIdGenerated;
      this.fetchDeposit.customFields.customFieldValues = [];
      let cForm = [];
      let isValidate = false;
      if (this.fetchDeposit.customFieldItems.length > 0) {
        this.fetchDeposit.customFieldItems.forEach((formSet) => {
          formSet.customFields.forEach((form) => {
            let key = form.customFieldKey;
            let value = this.$refs[key][0].value;

            if (
              ['customer link', 'group link', 'user link'].includes(form.dataType.toLowerCase()) &&
              value.length == 0
            ) {
              value = "";
            }
            if (form.dataType.toLowerCase() === "checkbox") {
              value = this.$refs[key][0].checked;
            }

            let required = form.customFieldLinks.find(
              (item) => item.entityLinkedKey === this.deposit.product.id
            )
            if (!value && required && required.isRequired) {
              this.alertWarning(`Some custom field value are required`);
              isValidate = true;
              form.customFieldNameErr = true;
              return;
            } else {
              form.customFieldNameErr = false;
            }

            if (this.$refs[key].length && this.$refs[key][0].value && value) {
              const formValObj = {
                customFieldSetGroupIndex: 0,
                indexInList: 0,
                customFieldKey: key,
                value: value.userKey || value.customerKey || value.groupKey || value,
              };
              cForm.push({ ...formValObj });
            } else {
              const formValObj = {
                customFieldSetGroupIndex: 0,
                indexInList: 0,
                customFieldKey: key,
                value: "",
              };
              cForm.push({ ...formValObj });
            }
            this.fetchDeposit.customFields.customFieldValues = cForm;
          });
        });
      }
      if(isValidate) {
        return;
      }
      this.fetchDeposit.customFields.url = "";
      this.fetchDeposit.customFields.entityKey = "";
      this.fetchDeposit.customFields.entityTypeKey = this.deposit.product.id;

      if (this.fetchDeposit.accountType !== "Fixed Deposit" || this.fetchDeposit.accountType !== "FixedDeposit") {
        this.loading = true;
        if(this.fetchDeposit.accountType.toLowerCase() == "settlement account") {
          this.fetchDeposit.recommendedDepositAmount = this.fetchDeposit.defaultDepositAmount;
        }
        ApiService.post(
          "DepositAccount/CreateDepositAccount",
          this.fetchDeposit
        )
          .then((resp) => {
            this.loading = false;
            this.alertSuccess("Deposit Account Created Successfully");
            document.getElementById("closeBtn").click();
            this.deposit.accountType = "";
            this.deposit.accountName = "";
            this.deposit.product = null;
            this.depositErrorForm = {
              assignedBranchKey: false,
              assignedUserKey: false,
            }
            this.viewRoute(resp.data.message);
          })
          .catch((e) => {
            this.loading = false;
            this.alertError(e.response.data.message);
          });
      } else {
        if(this.numberIsValidFD()) {
          return;
        }
        this.interestError = [];
        this.fetchDeposit.savingsGoalTarget = 0;
        if (
          this.fetchDeposit.interestRate <
            this.deposit.product.minInterestRateConstraints ||
          this.fetchDeposit.interestRate >
            this.deposit.product.maxInterestRateConstraints
        ) {
          this.alertError(
            `Interest rate must be between ${this.deposit.product.minInterestRateConstraints} and ${this.deposit.product.maxInterestRateConstraints}`
          );
          this.interestError.push(
            `Interest must be between ${this.deposit.product.minInterestRateConstraints} and ${this.deposit.product.maxInterestRateConstraints}`
          );
        }
        if (!this.interestError.length) {
          this.loading = true;
          ApiService.post(
            "DepositAccount/CreateDepositAccount",
            this.fetchDeposit
          )
            .then((resp) => {
              this.loading = false;
              this.alertSuccess("Deposit Account Created Successfully");
              document.getElementById("closeBtn").click();
              this.deposit.accountType = "";
              this.deposit.accountName = "";
              this.deposit.product = [];
              this.viewRoute(resp.data.message);
            })
            .catch((e) => {
              this.loading = false;
              this.alertError(e.response.data.message);
            });
        }
      }
    },
    async getIdTemplates() {
      try {
        this.isLoading = true;
        const res = await idTemplatesService.get({ pageSize: 100000, pageIndex: 1 });
        this.idTemplates = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        if (error) return this.alertError(error);
      }
    },
    resetFetchForm() {
      this.fetchDeposit = {
        accountHolderKey: "",
        accountHolderType: "",
        accountType: "",
        assignedBranchKey: "",
        assignedCenterKey: "",
        assignedUserKey: "",
        currencyCode: "",
        interestSettingsKey: "",
        savingsAccountName: "",
        savingsAccountNotes: "",
        savingsProductKey: "",
        interestRate: "",
        maxWithdrawalAmount: "",
        defaultOpeningBalance: "",
        maxOpeningBalance: "",
        minOpeningBalance: "",
        openingBalance: "",
        defaultDepositAmount: "",
        savingsGoalTarget: 0,
        uniqueId: "",
        interestError: [],
      };
    },
    removeNextDepositForm() {
      this.depositForm = true;
      this.deposit.product = null;
      this.nextDepositForm = false;
      this.resetDepositForm();
      this.resetFetchForm();
      this.depositErrorForm = {
        assignedBranchKey: false,
        assignedUserKey: false,
      }
      document.getElementById("closeBtn").click();
    },
    getFieldSets(usage) {
      this.fetchDeposit.customFieldItems = [];
      const p = {
        customFieldSetType: "DepositAccounts",
        entityLinkedKey: usage.id
      }
      GeneralApiService.post(
        'CustomFields/FieldSets/FieldSetType', 
        p
      )
        .then((res) => res.data.data)
        .then((res) => {
          this.fetchDeposit.customFieldItems = res.items;
        })
        .catch(() => {
        });
    },
    isInGroupMenu(val) {
      var __FOUND = this.groupedMenu.find(function (menu) {
        if (menu?.parentMenuName && menu?.parentMenuName.toLowerCase() == val.toLowerCase()) return true;
      });
      return __FOUND;
    },
  },
  async created() {
    this.getOrgBranding();
    // this.getCentres();
    this.getBranches();
    this.getGroupRoleNames();
    this.getCustomers();
    this.getIdTemplates();
    this.getAllEmployers();
  },
  filters: {
    base64ToImg: function (value) {
      const imgUrl = "data:image/jpg;base64," + value;
      return imgUrl;
    },
    kb(val) {
      return Math.floor(val / 1024);
    },
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  }
});
</script>
<style scoped lang="scss">
@import "https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css";
.logo__area {
  width: 112px;
  height: 50px;
  display: flex;
  align-items: center;

  & a {
    height: 100%;
    width: 100%;
  }
}
.no--padding {
  padding: 0 !important;
}
.text--600 {
  font-weight: 600 !important;
  font-family: Montserrat-semibold !important;
}
button:disabled {
  opacity: 0.6;
  cursor: no-drop;
}
.search__box {
  display: flex;
  width: 40%;
  background: white;
  position: absolute;
  margin-top: 35px;
  box-shadow: 1px 1px;
  border-radius: 3px;
  /* overflow-y: scroll */
}
.search__item {
  width: 100%;
  justify-content: flex-end;
  height: 24px;
}
.search__result__item {
  width: 100%; 
  display: flex;
  flex-direction: column;
  height: 24px;
}
.hover--text {
  color: #000;
  font-weight:700;
}
.hover--text:hover {
  background: #ffdecc !important;
  font-weight:700;
  color: #000;
}
.download-attach {
  text-decoration: underline;
  color: #F58634;
  font-size: 14px;
  cursor: pointer;
}
.new--item {
  border-top: 1px solid #212529;
}

.card {
  position: relative;
  // margin: 150px auto;
  width: 100%;
  padding: 20px;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 0;
  .content {
    display: flex;
    flex-direction: column;
  }
  h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }
  h2 {
    font-size: 13px;
    letter-spacing: 0.5px;
    font-weight: 300;
  }
  .social {
    margin-bottom: 5px;
    p{
      color: black;
      font-weight: 300;
    }
  }
.slide-enter { 
  opacity: 0;
}
.slide-enter-active {
  animation: slide-in 1s ease-in forwards;
  transition: opacity .5s;
}
.slide-leave {
  // initial state set in keyframe
}
.slide-leave-active {
  animation: slide-out 1s ease-out forwards; 
  opacity: 0;
  transition: opacity 1s;
}
@keyframes slide-in {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(20px);
  }
}
}
</style>