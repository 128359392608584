<template>
  <aside class="sidebar">
    <nav>
      <ul>
        <li
          :class="[
            'sidebar__link has-submenu',
            isGeneralLinkMatched ? 'sidebar__link--active' : '',
            isOrgLinkMatched,
          ]"
        >
          <a class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital">
              general setup
            </span>

            <div class="d-flex align-items-center justify-content-center">
              <span
                class="sidebar__link__subtitle"
                style="word-break:break-word;"
              >
                Set up Core Lending  Management System with all the details for your organization. 
                Define your institution details and contact information, 
                configure your holidays and working days as well as your labels 
                and templates. Customize your internal control settings to prevent 
                errors and fraud and manage your custom fields.
              </span>
              <ion-icon
                class="sidebar__link__subtitle"
                name="chevron-forward-outline"
              ></ion-icon>
            </div>
          </a>
          <div class="submenu">
            <ul class="sidemenu__container">
              <li class="submenu__item" v-if="userCanViewOrgDetails">
                <router-link
                  to="/organization-details"
                  tag="a"
                  class="submenu__link"
                  >Organization Details</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewCurrency">
                <router-link to="/currency" tag="a" class="submenu__link"
                  >Currency</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewRiskLevel">
                <router-link to="/risk-levels" tag="a" class="submenu__link"
                  >Risk Levels</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewCustomerTypes">
                <router-link to="/customer-types" tag="a" class="submenu__link"
                  >Customer Types</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewInternalControls">
                <router-link
                  to="/internal-controls"
                  tag="a"
                  class="submenu__link"
                  >Internal Controls</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewTemplates">
                <router-link to="/id-templates" tag="a" class="submenu__link"
                  >ID Templates</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewStates">
                <router-link to="/states" tag="a" class="submenu__link"
                  >State</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewGroupRoles">
                <router-link to="/group-roles" tag="a" class="submenu__link"
                  >group roles</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanManageBranding">
                <router-link
                  to="/organization-branding"
                  tag="a"
                  class="submenu__link"
                  >branding</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewBank">
                <router-link to="/bank-setup" tag="a" class="submenu__link"
                  >Bank</router-link
                >
              </li>
            </ul>
          </div>
        </li>
        <li
          :class="[
            'sidebar__link has-submenu',
            isEmployerLinkMatched ? 'sidebar__link--active' : '',
          ]"
          v-if="userCanViewEmployer || userCanViewEmployerCat || userCanViewEmployerSubCat"
        >
          <a class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital">
              employer setup
            </span>

            <div class="d-flex align-items-center justify-content-center">
              <span
                class="sidebar__link__subtitle"
                style="word-break:break-word;"
              >
                Set up Core Lending  Management System with all the employers details for your organization.
              </span>
              <ion-icon
                class="sidebar__link__subtitle"
                name="chevron-forward-outline"
              ></ion-icon>
            </div>
          </a>
          <div class="submenu">
            <ul class="sidemenu__container">
              <li class="submenu__item" v-if="userCanViewEmployer">
                <router-link to="/employers" tag="a" class="submenu__link"
                  >Employers</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewEmployerCat">
                <router-link
                  to="/employers-categories"
                  tag="a"
                  class="submenu__link"
                  >Employers Categories</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewEmployerSubCat">
                <router-link to="/sub-categories" tag="a" class="submenu__link"
                  >Sub Categories</router-link
                >
              </li>
            </ul>
          </div>
        </li>
        <li
          :class="[
            'sidebar__link has-submenu',
            isOrgLinkMatched ? 'sidebar__link--active' : '',
          ]"
        >
          <a class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital">
              organization
            </span>

            <div class="d-flex align-items-center justify-content-center">
              <span
                class="sidebar__link__subtitle"
                style="word-break:break-word;"
              >
                Structure your organization by creating and editing branches & centres.
                You can then assign customers, groups and users to branches to capture 
                how your organization works. 
              </span>
              <ion-icon
                class="sidebar__link__subtitle"
                name="chevron-forward-outline"
              ></ion-icon>
            </div>
          </a>
          <div class="submenu">
            <ul class="sidemenu__container">
              <li class="submenu__item">
                <router-link to="/branches" tag="a" class="submenu__link"
                  >Branches</router-link
                >
              </li>
              <li class="submenu__item">
                <router-link to="/centres" tag="a" class="submenu__link"
                  >Centres</router-link
                >
              </li>
              <!-- <li class="submenu__item">
                <a class="submenu__link">holidays</a>
              </li> -->
            </ul>
          </div>
        </li>
        <li
          :class="[
            'sidebar__link has-submenu',
            isAccessLinkMatched ? 'sidebar__link--active' : '',
          ]"
          v-if="userCanViewRoles || userCanViewUser"
        >
          <a class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> access </span>

            <div class="d-flex align-items-center justify-content-center">
              <span
                class="sidebar__link__subtitle"
                style="word-break:break-word;"
              >
                Open up Core Lending  Management System to your entire organization by creating and managing your users. 
                Create and edit users or modify permissions for existing ones to give everyone 
                the level of access they need and the rights to work with the system.
              </span>
              <ion-icon
                class="sidebar__link__subtitle"
                name="chevron-forward-outline"
              ></ion-icon>
            </div>
          </a>
          <div class="submenu">
            <ul class="sidemenu__container">
              <li class="submenu__item" v-if="userCanViewRoles">
                <router-link to="/roles" tag="a" class="submenu__link"
                  >roles</router-link
                >
              </li>
              <li class="submenu__item" v-if="userCanViewUser">
                <router-link to="/users" tag="a" class="submenu__link"
                  >users</router-link
                >
              </li>
              <!-- <li class="submenu__item">
                <router-link to="/preferences" class="submenu__link" tag="a"
                  >preferences</router-link
                >
              </li>
              <li class="submenu__item">
                <router-link to="/authentication" tag="a" class="submenu__link"
                  >Federated Authentication</router-link
                >
              </li> -->
            </ul>
          </div>
        </li>
        <li
          :class="[
            'sidebar__link has-submenu',
            isProductLinkMatched ? 'sidebar__link--active' : '',
          ]"
        >
          <a class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> product </span>
            <div class="d-flex align-items-center justify-content-center">
              <span
                class="sidebar__link__subtitle"
                style="word-break:break-word;"
              >
                Manage the products offerings that you have available to your customers 
                by creating and editing loan and deposit products.
              </span>
              <ion-icon
                class="sidebar__link__subtitle"
                name="chevron-forward-outline"
              ></ion-icon>
            </div>
          </a>
          <div class="submenu">
            <ul class="sidemenu__container">
              <li class="submenu__item">
                <router-link to="/deposits" tag="a" class="submenu__link"
                  >Deposits</router-link
                >
              </li>
              <li class="submenu__item">
                <router-link to="/loans" tag="a" class="submenu__link"
                  >Loan</router-link
                >
              </li>
            </ul>
          </div>
        </li>
        <li
          :class="[
            'sidebar__link has-submenu',
            isFieldLinkMatched ? 'sidebar__link--active' : '',
          ]"
          v-if="userCanViewCustomField"
        >
          <a class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> fields </span>
            <div class="d-flex align-items-center justify-content-center">
              <span
                class="sidebar__link__subtitle"
                style="word-break:break-word;"
              >
                Determine what information needs to be included in the customers profiles, 
                groups, centres, branches, loan, guarantors and savings accounts, 
                in addition to the default fields provided by Zedvance.
              </span>
              <ion-icon
                class="sidebar__link__subtitle"
                name="chevron-forward-outline"
              ></ion-icon>
            </div>
          </a>
          <div class="submenu">
            <ul class="sidemenu__container">
              <li class="submenu__item">
                <router-link to="/field-customers" tag="a" class="submenu__link"
                  >Customers</router-link
                >
              </li>
              <li class="submenu__item">
                <router-link to="/field-group" tag="a" class="submenu__link"
                  >Groups</router-link
                >
              </li>
              <!-- <li class="submenu__item">
                <a href="credit.html" class="submenu__link"
                  >credit arrangement</a
                >
              </li> -->
              <li class="submenu__item">
                <router-link
                  to="/field-loan-accounts"
                  tag="a"
                  class="submenu__link"
                  >Loan Accounts</router-link
                >
              </li>
              <li class="submenu__item">
                <router-link
                  to="/field-guarantors"
                  tag="a"
                  class="submenu__link"
                  >Guarantors</router-link
                >
              </li>
              <li class="submenu__item">
                <router-link
                  to="/field-deposit-accounts"
                  tag="a"
                  class="submenu__link"
                  >Deposit Accounts</router-link
                >
              </li>
              <!-- <li class="submenu__item">
                <router-link
                  to="/field-transactions"
                  tag="a"
                  class="submenu__link"
                  >Transactions</router-link
                >
              </li> -->
              <li class="submenu__item">
                <router-link to="/field-branches" tag="a" class="submenu__link"
                  >Branches</router-link
                >
              </li>
              <li class="submenu__item">
                <router-link to="/field-centres" tag="a" class="submenu__link"
                  >Centres</router-link
                >
              </li>
              <!-- <li class="submenu__item">
                <router-link to="/field-users" tag="a" class="submenu__link"
                  >Users</router-link
                >
              </li>
              <li class="submenu__item">
                <a href="field-employees.html" class="submenu__link"
                  >employee details</a
                >
              </li> -->
            </ul>
          </div>
        </li>

        <li
          :class="[
            'sidebar__link',
            currentRouteName === 'views' ? 'sidebar__link--active' : '',
          ]"
          v-if="userCanViewViews"
        >
          <router-link to="/views" tag="a" class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> views </span>
            <span
              class="sidebar__link__subtitle"
              style="word-break:break-word;"
            >
              Manage all the custom views in the system, regardless of their visibility rights. 
              Add, delete or update some of the existing ones.
            </span>
          </router-link>
        </li>

        <!-- <li class="sidebar__link">
          <a class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> sms </span>
            <span
              class="sidebar__link__subtitle sidebar__link__subtitle--overflow"
            >
              Manage the products offerings that you have
            </span>
          </a>
        </li>
        <li class="sidebar__link">
          <a class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> email </span>
            <span
              class="sidebar__link__subtitle sidebar__link__subtitle--overflow"
            >
              Manage the products offerings that you have
            </span>
          </a>
        </li> -->

        <li
          :class="[
            'sidebar__link has-submenu',
            isNotificationLinkMatched ? 'sidebar__link--active' : '',
          ]"
        >
          <a class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> notifications </span>
            <div class="d-flex align-items-center justify-content-center">
              <span
                class="sidebar__link__subtitle"
                style="word-break:break-word;"
              >
                Customize your workflows by pushing automated notifications to 
                your local ecosystem when transactions are posted, customers 
                or accounts are created and more.
              </span>
              <ion-icon
                class="sidebar__link__subtitle"
                name="chevron-forward-outline"
              ></ion-icon>
            </div>
          </a>
          <div class="submenu">
            <ul class="sidemenu__container">
              <li class="submenu__item">
                <router-link to="/notifications" tag="a" class="submenu__link"
                  >Notifications</router-link
                >
              </li>
              <li class="submenu__item">
                <router-link
                  to="/predefined-notifications"
                  tag="a"
                  class="submenu__link"
                  >Predefined Notifications</router-link
                >
              </li>
            </ul>
          </div>
        </li>

        <li
          :class="[
            'sidebar__link',
            isTemplateLinkMatched ? 'sidebar__link--active' : '',
          ]"
        >
          <router-link to="/templates" class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> Templates </span>
            <span
              class="sidebar__link__subtitle"
              style="word-break:break-word;"
            >
              Standardize the communication with your customers by using templates 
              for manual SMS and email messages and improve your staff workflows by 
              using templates for their tasks.
            </span>
          </router-link>
        </li>

        <li
          :class="[
            'sidebar__link',
            isAppsMatched ? 'sidebar__link--active' : '',
          ]"
        >
          <router-link to="/apps" class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> Apps </span>
            <span
              class="sidebar__link__subtitle"
              style="word-break:break-word;"
            >
              Integrate  with your local ecosystem. Use APIs to easily 
              incorporate services like credit scoring, ATMs, agent network 
              and mobile money providers or build your own apps to expand 
              the core functionality for your unique needs.
            </span>
          </router-link>
        </li>

        <li
          :class="[
            'sidebar__link',
            isAccounting ? 'sidebar__link--active' : '',
          ]"
        >
          <router-link to="/finance_coa" class="d-block sidebar__item">
            <span class="sidebar__link__title text--capital"> Accounting </span>
            <span
              class="sidebar__link__subtitle"
              style="word-break:break-word;"
            >
              View accounting details for your organization.
            </span>
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import $ from "@/assets/js/components/jquery.min.js";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      generalSetupLink: [
        "organization-details",
        "currency",
        "risk-levels",
        "rates",
        "customer-types",
        "internal-controls",
        "id-templates",
        "group-roles",
        "organization-branding",
        "bank-setup",
      ],
      orgLink: ["branches", "centres"],
      accessLink: ["roles", "users", "preferences", "authentication"],
      productLink: ["deposits", "loans"],
      views: ["views"],
      notificationLink: ["notifications", "predefined-notifications"],
      template: ["sms_template"],
      app: ["extension_apps"],
      accounting: ["finance_coa"],
      fieldLink: [
        "field-customers",
        "field-group",
        "field-loan-accounts",
        "field-guarantors",
        "field-deposit-accounts",
        "field-transactions",
        "field-branches",
        "field-centres",
        "field-users",
        "field-employee-details",
      ],
      employerLink: ["employer", "employers-category", "sub-category"],
    };
  },
  components: {},
  mounted() {
    this.subMenu();
    this.sideBar();
    this.dropDown();
  },
  methods: {
    subMenu() {
      const sidemnus = document.querySelectorAll(".has-submenu");

      sidemnus.forEach((element) => {
        element.addEventListener("mouseenter", function () {
          const distanceTop =
            window.pageYOffset + element.getBoundingClientRect().top;
          const sideElementWidth = element.offsetWidth;
          const distanceLeft =
            window.pageXOffset + element.getBoundingClientRect().left;
          const newOffset = distanceLeft + sideElementWidth;
          element.lastElementChild.style.transform = `translate3d(${newOffset}px, ${distanceTop}px, 0)`;
        });
      });
    },
    sideBar() {
      const rightSidebar = document.querySelector(".sidebar-right");
      const morebtn = document.getElementById("more__btn");
      const closeSideBar = document.querySelector(".sidebar__close");

      if (morebtn) {
        morebtn.addEventListener("click", function () {
          rightSidebar.classList.add("show");
        });
      }

      closeSideBar.addEventListener("click", function () {
        rightSidebar.classList.remove("show");
      });
    },
    dropDown() {
      const $dropdown = $(".dropdown__hover");
      const $dropdownToggle = $(".dropdown-toggle");
      const $dropdownMenu = $(".dropdown__menu");
      const showClass = "show";

      $dropdown.hover(
        function () {
          const $this = $(this);
          $this.addClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "true");
          $this.find($dropdownMenu).addClass(showClass);
        },
        function () {
          const $this = $(this);
          $this.removeClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "false");
          $this.find($dropdownMenu).removeClass(showClass);
        }
      );
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    ...mapState({
      user: (state) => state.auth.user,
    }),

    userCanViewRoles() {
      const roles = this.user.scopes.find(item => item.scopeName === Role.Access);
      const { permissionsList } = roles;
      return permissionsList.CanViewRole;
    },

    userCanViewBank() {
      const bank = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = bank;
      return permissionsList.CanViewBank;
    },

    userCanViewOrgDetails() {
      const bank = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = bank;
      return permissionsList.CanViewOrganizationDetails;
    },

    userCanViewCurrency() {
      const currency = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = currency;
      return permissionsList.CanViewCurrency;
    },

    userCanViewRiskLevel() {
      const risk = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = risk;
      return permissionsList.CanViewRiskLevel;
    },

    userCanViewCustomerTypes() {
      const risk = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = risk;
      return permissionsList.CanViewCustomerTypes;
    },

    userCanManageBranding() {
      const risk = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = risk;
      return permissionsList.CanUploadLogo;
    },

    userCanViewInternalControls() {
      const risk = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = risk;
      return permissionsList.CanViewInternalControl;
    },

    userCanViewTemplates() {
      const risk = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = risk;
      return permissionsList.CanViewIDTemplate;
    },

    userCanViewStates() {
      const risk = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = risk;
      return permissionsList.CanViewState;
    },

    userCanViewGroupRoles() {
      const risk = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = risk;
      return permissionsList.CanViewGroupRole;
    },

    userCanViewUser() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Access);
      const { permissionsList } = user;
      return permissionsList.CanViewUser;
    },

    userCanViewEmployer() {
      const employer = this.user.scopes.find(item => item.scopeName === Role.EmployerSetup);
      const { permissionsList } = employer;
      return permissionsList.CanViewEmployer;
    },

    userCanViewEmployerCat() {
      const employerCat = this.user.scopes.find(item => item.scopeName === Role.EmployerSetup);
      const { permissionsList } = employerCat;
      return permissionsList.CanViewEmployerCategory;
    },

    userCanViewEmployerSubCat() {
      const employerCat = this.user.scopes.find(item => item.scopeName === Role.EmployerSetup);
      const { permissionsList } = employerCat;
      return permissionsList.CanViewEmployerSubCategory;
    },

    userCanViewViews() {
      const views = this.user.scopes.find(item => item.scopeName === Role.Views);
      const { permissionsList } = views;
      return permissionsList.CanViewViews;
    },

    userCanViewCustomField() {
      const views = this.user.scopes.find(item => item.scopeName === Role.CustomField);
      const { permissionsList } = views;
      return permissionsList.CanViewCustomField;
    },

    isGeneralLinkMatched() {
      return this.generalSetupLink.includes(this.currentRouteName);
    },

    isOrgLinkMatched() {
      return this.orgLink.includes(this.currentRouteName);
    },

    isAccessLinkMatched() {
      return this.accessLink.includes(this.currentRouteName);
    },

    isProductLinkMatched() {
      return this.productLink.includes(this.currentRouteName);
    },

    isNotificationLinkMatched() {
      return this.notificationLink.includes(this.currentRouteName);
    },

    isViewsLinkMatched() {
      return this.views.includes(this.currentRouteName);
    },

    isTemplateLinkMatched() {
      return this.template.includes(this.currentRouteName);
    },

    isAppsMatched() {
      return this.app.includes(this.currentRouteName);
    },

    isAccounting() {
      return this.accounting.includes(this.currentRouteName);
    },

    isFieldLinkMatched() {
      return this.fieldLink.includes(this.currentRouteName);
    },

    isEmployerLinkMatched() {
      return this.employerLink.includes(this.currentRouteName);
    },

    currentRouteName() {
      return this.$route.name;
    },

    /**
     * Get extra classes for menu based on the options
     */
    // asideMenuClass() {
    //   const classes = this.getClasses("aside_menu");
    //   if (typeof classes !== "undefined") {
    //     return classes.join(" ");
    //   }
    //   return null;
    // }
  },
};
</script>
