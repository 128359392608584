<template>
  <div class="sidebar-right">
    <div
      class="
        sidebar-right__header
        d-flex
        align-items-center
        justify-content-between
      "
    >
      <span class="sidebar-right__header__text d-block text--capital">
        more options
      </span>
      <ion-icon
        name="close-circle-outline"
        class="sidebar-right__header__icon sidebar__close"
      ></ion-icon>
    </div>
    <div class="sidebar-right__body">
      <ul id="accordion">
        
        <li v-b-toggle="'sideBarIFrameApp'" class="sidebar-right__item">
          <h5
            class="
              w-100
              sidebar-right__link
              mb-0
              d-flex
              align-items-center
              justify-content-between
            "
          >
            Apps
            <ion-icon name="chevron-down-outline"></ion-icon>
          </h5>
          <b-collapse id="sideBarIFrameApp">
            <div class="px-2">
              <ul v-for="app in extensionApps" :key="app.id">
                <li
                  v-if="app.state == 0"
                  class="text--capital sidebar-right__link"
                  style="color: #f58634 !important"
                  @click="openApp(app.general_app_key)"
                >
                  {{ app.name ? app.name : 'Extension Url' }}
                  <!-- <router-link
                    :to="`/extension/${app.general_app_key}/EXTENSION_MENU`"
                    tag="a"
                    >{{ app.name ? app.name : 'Extension Url' }}</router-link
                  > -->
                </li>
              </ul>
            </div>
          </b-collapse>
        </li>

        <li v-b-toggle="'sideBarChildOne'" class="sidebar-right__item">
          <h5
            class="
              w-100
              sidebar-right__link
              mb-0
              d-flex
              align-items-center
              justify-content-between
            "
            v-if="userCanViewInstallments"
          >
            installment
            <ion-icon name="chevron-down-outline"></ion-icon>
          </h5>
          <b-collapse id="sideBarChildOne">
            <div class="px-2">
              <ul>
                <li class="text--capital sidebar-right__link">
                  <router-link to="/all-installment" tag="a" class="no-link-color">All Installment</router-link>
                </li>
              </ul>
            </div>
          </b-collapse>
        </li>

        <!-- <li class="sidebar-right__item">
          <a class="sidebar-right__link"> products </a>
        </li>

        <li class="sidebar-right__item">
          <a class="sidebar-right__link"> reporting </a>
        </li>

        <li class="sidebar-right__item">
          <a class="sidebar-right__link"> accounting </a>
        </li> -->

        <!-- <li v-b-toggle="'sideBarChildTwo'" class="sidebar-right__item">
          <h5
            class="
              w-100
              sidebar-right__link
              mb-0
              d-flex
              align-items-center
              justify-content-between
            "
          >
            apps
            <ion-icon name="chevron-down-outline"></ion-icon>
          </h5>
          <b-collapse id="sideBarChildTwo">
            <div class="px-2">
              <ul>
                <li class="text--capital sidebar-right__link">action</li>
                <li class="text--capital sidebar-right__link">action</li>
                <li class="text--capital sidebar-right__link">action</li>
              </ul>
            </div>
          </b-collapse>
        </li> -->

        <li
          data-toggle="collapse"
          class="sidebar-right__item"
          data-target="#sideBarChildTwo"
          aria-expanded="true"
          aria-controls="sideBarChildTwo"
        >
          <router-link class="sidebar-right__link" to="/administration-menu">
            administration
          </router-link>
        </li>

        <li
          data-toggle="collapse"
          class="sidebar-right__item"
          data-target="#sideBarChildTwo"
          aria-expanded="true"
          aria-controls="sideBarChildTwo"
          v-if="userCanManageDisbursement"
        >
          <router-link to="/disbursement" tag="a" class="sidebar-right__link"
            >Disbursement Manager</router-link
          >
        </li>

        <li v-b-toggle="'sideBarChildFour'" class="sidebar-right__item">
          <h5
            class="
              w-100
              sidebar-right__link
              mb-0
              d-flex
              align-items-center
              justify-content-between
            "
          >
            Underwriting
            <ion-icon name="chevron-down-outline"></ion-icon>
          </h5>
          <b-collapse id="sideBarChildFour">
            <div class="px-2">
              <ul>
                <li class="text--capital sidebar-right__link">
                  <router-link
                    to="/underwriting-general"
                    tag="a"
                    class="no-link-color"
                    v-if="userCanSeeGeneralDashboard"
                    >General Dashboard</router-link
                  >
                </li>
                <li class="text--capital sidebar-right__link">
                  <router-link
                    to="/underwriting-dashboard"
                    tag="a"
                    class="no-link-color"
                    v-if="userCanSeeDashboard"
                    >Dashboard</router-link
                  >
                </li>
                <li class="text--capital sidebar-right__link">
                  <router-link to="/underwriting" tag="a" class="no-link-color" v-if="userCanViewQueues"
                    >Queue Management</router-link
                  >
                </li>
                <li class="text--capital sidebar-right__link">
                  <router-link
                    to="/underwriting-reason"
                    tag="a"
                    class="no-link-color"
                    v-if="userCanSeeReasons"
                    >Reasons</router-link
                  >
                </li>
              </ul>
            </div>
          </b-collapse>
        </li>

        <!-- Bulk Repayment Manager -->
        <li
          data-toggle="collapse"
          class="sidebar-right__item"
          data-target="#sideBarChildTwo"
          aria-expanded="true"
          aria-controls="sideBarChildTwo"
          v-if="userCanApproveBulkUpload"
        >
          <router-link
            to="/bulk-repayment-manager"
            tag="a"
            class="sidebar-right__link"
            >Bulk Repayment Manager</router-link
          >
        </li>

        <!-- Bulk Repayment Approval -->
        <li
          data-toggle="collapse"
          class="sidebar-right__item"
          data-target="#sideBarChildTwo"
          aria-expanded="true"
          aria-controls="sideBarChildTwo"
          v-if="userCanApproveBulk"
        >
          <router-link
            to="/bulk-repayment-approval"
            tag="a"
            class="sidebar-right__link"
            >Bulk Repayment Approval</router-link
          >
        </li>

        <li
          data-toggle="collapse"
          class="sidebar-right__item"
          data-target="#sideBarChildTwo"
          aria-expanded="true"
          aria-controls="sideBarChildTwo"
          v-if="userCanBulkAssignAccountOfficer"
        >
          <router-link
            to="/bulk-reassign-account-officer"
            tag="a"
            class="sidebar-right__link"
            >Reassign Account Officer</router-link
          >
        </li>

        <li
          data-toggle="collapse"
          class="sidebar-right__item"
          data-target="#sideBarChildTwo"
          aria-expanded="true"
          aria-controls="sideBarChildTwo"
          v-if="userCanViewTeam"
        >
          <router-link
            to="/teams_management"
            tag="a"
            class="sidebar-right__link"
            >Teams Management</router-link
          >
        </li>

        <li
          data-toggle="collapse"
          class="sidebar-right__item"
          data-target="#sideBarChildTwo"
          aria-expanded="true"
          aria-controls="sideBarChildTwo"
        >
          <router-link
            to="/sales_report_summary"
            tag="a"
            class="sidebar-right__link"
            >Sales Report</router-link
          >
        </li>

        <!-- Dynamic menus -->
        <li
          v-b-toggle="unspacedViews(row.parentMenuName)"
          class="sidebar-right__item border-top"
          v-for="(row, index) in isACustomMenu()"
          :key="index"
        >
          <h5
            class="
              w-100
              sidebar-right__link
              mb-0
              d-flex
              align-items-center
              justify-content-between
            "
          >
            {{ row.parentMenuName }}
            <ion-icon name="chevron-down-outline" v-if="row.viewMenus.length"></ion-icon>
          </h5>
          <b-collapse :id="unspacedViews(row.parentMenuName)">
            <div class="px-2" v-if="row.viewMenus.length">
              <ul 
                v-for="r in row.viewMenus"
                :key="r.viewKey"
              >
                <li
                  class="text--capital sidebar-right__link"
                >
                  <router-link
                    :to="`/viewreport/${r.viewKey}`"
                    class="no-link-color"
                    >{{ r.viewName }}</router-link
                  >
                </li>
              </ul>
            </div>
          </b-collapse>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { GET_GROUPED_MENU } from "@/core/services/store/types";
import { GET_ALL_EXTENSION_APP } from "@/core/services/store/extensionapp.module";
import { Role } from "@/core/services/role.js";

export default {
  name: "RightAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
    };
  },
  components: {},
  mounted() {
    this.getGroupedMenu();
    this.getAllExtensionApp();
    this.isACustomMenu();
  },
  methods: {
    getGroupedMenu() {
      this.$store.dispatch(GET_GROUPED_MENU);
    },
    isACustomMenu() {
      var list = [
        "clients",
        "groups",
        "deposits",
        "loans",
        "loan transactions",
        "deposit transactions",
        "activities",
        "branches",
        "centres",
        "Users",
      ];
      // var customMenu = this.groupedMenu.filter((x) => x.isCustom);
      var customMenu = this.groupedMenu.filter(
        (x) =>
          !list.includes(x.parentMenuName.toLowerCase()) && x.viewMenus.length
      );
      return customMenu;
    },
    unspacedViews(string) {
      return string.replace(/\s/g, '');
    },
    getAllExtensionApp() {
      this.$store.dispatch(GET_ALL_EXTENSION_APP);
    },
    openApp(key) {
      // const separate = this.$route.path.split('/')
      this.$router.push({ path: `/extension/${key}/EXTENSION_MENU` })
      // if(separate[1] == "extension") {
      //   window.location.reload();
      // }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    ...mapState({
      groupedMenu: (state) => state.views?.groupedMenu?.data || [],
      extensionApps: (state) => state.extensionapp && state.extensionapp.allExtensionApp
      ? state.extensionapp.allExtensionApp.generalapps : [],
      user: (state) => state.auth.user,
    }),
    userCanManageDisbursement() {
      const disbursement = this.user.scopes.find(item => item.scopeName === Role.CreditOperations);
      const { permissionsList } = disbursement;
      return permissionsList.CanDisbursementManagement;
    },
    userCanApproveBulk() {
      const disbursement = this.user.scopes.find(item => item.scopeName === Role.CreditOperations);
      const { permissionsList } = disbursement;
      return permissionsList.CanBulkRepaymentApproval;
    },
    userCanApproveBulkUpload() {
      const disbursement = this.user.scopes.find(item => item.scopeName === Role.CreditOperations);
      const { permissionsList } = disbursement;
      return permissionsList.CanBulkRepaymentUpload;
    },
    userCanDisburse() {
      const disbursement = this.user.scopes.find(item => item.scopeName === Role.CreditOperations);
      const { permissionsList } = disbursement;
      return permissionsList.CanDisbursementManagement;
    },
    userCanSeeDashboard() {
      // CanViewUnderwritingdashboard
      const underwriting = this.user.scopes.find(item => item.scopeName === Role.Underwrting);
      const { permissionsList } = underwriting;
      return permissionsList.CanViewUnderwritingdashboard;
    },
    userCanSeeGeneralDashboard() {
      const underwriting = this.user.scopes.find(item => item.scopeName === Role.Underwrting);
      const { permissionsList } = underwriting;
      return permissionsList.CanViewGeneralUnderwrittingDashboard;
    },
    userCanSeeReasons() {
      const underwriting = this.user.scopes.find(item => item.scopeName === Role.Underwrting);
      const { permissionsList } = underwriting;
      return permissionsList.CanViewReasons;
    },
    userCanViewInstallments() {
      const loans = this.user.scopes.find(item => item.scopeName === Role.LoanAccounts);
      const { permissionsList } = loans;
      return permissionsList.CanViewInstallment;
    },
    userCanBulkAssignAccountOfficer() {
      const team = this.user.scopes.find(item => item.scopeName === Role.TeamManagement);
      const { permissionsList } = team;
      return permissionsList.CanBulkReassignAccountOfficer;
    },
    userCanViewQueues() {
      const underwriting = this.user.scopes.find(item => item.scopeName === Role.Underwrting);
      const { permissionsList } = underwriting;
      return permissionsList.CanViewQueues;
    },
    userCanViewTeam() {
      const team = this.user.scopes.find(item => item.scopeName === Role.TeamManagement);
      const { permissionsList } = team;
      return permissionsList.CanViewTeam;
    }
  },
};
</script>
<style lang="scss" scoped>
.no-link-color {
  color: #1e1f20 !important;
}
</style>
